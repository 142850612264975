<mat-dialog-content>
  <h3>{{data.offsetDialogTitle}}</h3>
  <div>
    <colimo-chart-table *ngIf="data.mwgData"
                        [dataSet]="data.mwgData.data"
                        [angles]="data.mwgData.angles"
                        [table]="data.mwgData.table"
                        [tolerance]="data.mwgData.tolerances"
                        [batchType]="data.mwgData.batchType"
                        [filters]="null"
                        [selectedAngles]="[true, true, true, true,true, true]"
                        [colorIndicator]="null"
                        class="vector-ref-chart-table">
    </colimo-chart-table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1">Ok</button>
</mat-dialog-actions>
