import {Component, Inject} from '@angular/core';
import {IMwgData} from "../../../models/chart-mwg.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'colimo-offset-dialog',
  templateUrl: './offset-dialog.component.html',
  styleUrls: ['./offset-dialog.component.scss']
})
export class OffsetDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      offsetDialogTitle: string;
      mwgData: IMwgData;
    }) {}
}
