import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard, RedirectCcLoginGuard, SkipLoginGuard } from 'colimo-ui-library';
import { AlienCheckPageComponent } from './pages/alien-check-page/alien-check-page.component';
import { AppInfoComponent } from './pages/app-info/app-info.component';
import { BatchPageComponent } from './pages/batch/batch-page.component';
import { CarReportComponent } from './pages/car-report/car-report.component';
import { ColorDashboardComponent } from './pages/color-dashboard/color-dashboard.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ErrorPageComponent } from './pages/error/error-page.component';
import { LoginComponent } from './pages/login/login.component';
import { ModelDashboardComponent } from './pages/model-dashboard/model-dashboard.component';
import { PaintlineDashboardComponent } from './pages/paintline-dashboard/paintline-dashboard.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProjectPageComponent } from './pages/project/project-page.component';
import { ReportPageComponent } from './pages/report/report-page.component';
import {MultiBatchPageComponent} from './pages/multi-batch/multi-batch-page/multi-batch-page.component';
import {NotificationPageComponent} from './pages/notification-page/notification-page.component';
import {VectorTintingPageComponent} from './pages/vector-tinting/vector-tinting-page/vector-tinting-page.component';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [SkipLoginGuard] },
  { path: 'login/legacy/:show', component: LoginComponent, canActivate: [SkipLoginGuard], title: 'Login' },
  {
    path: 'color',
    component: ColorDashboardComponent,
    canActivate: [AuthenticationGuard],
    title: 'Color Dashboard'
  },
  {
    path: 'paintline',
    component: PaintlineDashboardComponent,
    canActivate: [AuthenticationGuard],
    title: 'Paintline Dashboard'
  },
  {
    path: 'model',
    component: ModelDashboardComponent,
    canActivate: [AuthenticationGuard],
    title: 'Model Dashboard'
  },
  {
    path: 'report',
    component: ReportPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'KPI Report'
  },
  {
    path: 'alien-check',
    component: AlienCheckPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Car Check'
  },
  {
    path: 'app-info',
    component: AppInfoComponent,
    canActivate: [AuthenticationGuard],
    title: 'App Information'
  },
  {
    path: 'car-report',
    component: CarReportComponent,
    canActivate: [AuthenticationGuard],
    title: 'Car Report'
  },
  {
    path: 'project/:projectId/batch/:batchId/line/:lineId',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/color/batch/:batchId/line/:lineId',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/batch/:batchId/line/:lineId/chart/:chartType',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/color/batch/:batchId/line/:lineId/chart/:chartType',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/batch/:batchId/line/:lineId/model/:modelId/chart/:chartType',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/color/batch/:batchId/line/:lineId/model/:modelId/chart/:chartType',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/batch/:batchId/line/:lineId/chart/:chartType/:projectPage',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/color/batch/:batchId/line/:lineId/chart/:chartType/:projectPage',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/batch/:batchId/line/:lineId/model/:modelId/chart/:chartType/:projectPage',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/color/batch/:batchId/line/:lineId/model/:modelId/chart/:chartType/:projectPage',
    component: BatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Batch Details (Charge)'
  },
  {
    path: 'project/:projectId/multi-batch/:batches/line/:lines/model/:modelId/chart/:chartType/:projectPage',
    component: MultiBatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Multi Batch Details'
  },
  {
    path: 'project/:projectId/color/multi-batch/:batches/line/:lines/chart/:chartType/:projectPage',
    component: MultiBatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Multi Batch Details'
  },
  {
    path: 'project/:projectId/multi-batch/:batches/line/:lines/chart/:chartType/:projectPage',
    component: MultiBatchPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Multi Batch Details'
  },
  {
    path: 'project/:projectId',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/color',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/line/:lineId',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/color/line/:lineId',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/line/:lineId/model/:modelId',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/color/line/:lineId/model/:modelId',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/color/page/:projectPage',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/line/:lineId/page/:projectPage',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/color/line/:lineId/page/:projectPage',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/line/:lineId/model/:modelId/page/:projectPage',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'project/:projectId/color/line/:lineId/model/:modelId/page/:projectPage',
    component: ProjectPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Project Overview'
  },
  {
    path: 'color/vector-tinting/project/:projectId/batch/:batchId/:vector',
    component: VectorTintingPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Vector Tinting (Charge)'
  },
  {
    path: 'data-protection',
    component: PrivacyPolicyComponent,
    canActivate: [AuthenticationGuard],
    title: 'Privacy Policy'
  },
  {
    path: 'contact-page',
    component: ContactPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Contact'
  },
  {
    path: 'notification-center',
    component: NotificationPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Notifications'
  },
  {
    path: 'error/:errorCode',
    component: ErrorPageComponent,
    canActivate: [AuthenticationGuard],
    title: 'Error'
  },
  {
    path: 'colorCareLogin',
    canActivate: [RedirectCcLoginGuard],
    component: RedirectCcLoginGuard,
    title: 'Login'
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
