import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, EMPTY, Observable, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {Iline, IMwgData} from "../../models/chart-mwg.model";
import {MixedMeasurementResponse} from "../../models/vector-api/mixed-measurement-response.model";
import {Recipe} from "../../models/vector-api/recipe.model";
import {ChartFilters} from "../../models/chart-filters.model";
import {IBatch} from "../../models/batch.model";
import {RecipeEntry} from "../../models/vector-api/recipe-entry.model";
import {Ingredient} from "../../models/vector-api/ingredient.model";
import {IUserSummary, IUserSummaryActor, ServiceType, UserManagementClient} from "@colorcare/user-management-client";
import {OffsetDto} from "../../models/vector-api/offset-dto.model";

@Injectable({
  providedIn: 'root'
})
export class VectorService {

  private baseUrl: string;

  private mwgDataList$ = new BehaviorSubject<IMwgData[]>([]);

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBasePath;
  }

  public isAuthorizedForVector(): boolean {
    try {
      // check for vector permission in current token
      const bearerToken = localStorage.getItem('JWT');
      const token: string = bearerToken.substring(bearerToken.indexOf(' ') + 1);
      const encodedPayload = token.split('.')[1];
      const decodedPayload = JSON.parse(atob(encodedPayload));
      if (decodedPayload && decodedPayload.actors && decodedPayload.actors.length > 0) {
        const colimoActor = decodedPayload.actors.find(actor => actor.serviceType === 'colimo');
        if (colimoActor && colimoActor.permissions && colimoActor.permissions.length > 0) {
          return colimoActor.permissions.indexOf('VectorTinting') >= 0;
        }
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  public getMwgDataListObservable(): Observable<IMwgData[]> {
    return this.mwgDataList$.asObservable();
  }

  public updateMwgData(mwgDataList: IMwgData[]): void {
    this.mwgDataList$.next(mwgDataList);
  }

  public calculateMdE(mwgData: IMwgData, batch: IBatch): number {
    if (!mwgData || !mwgData.data) {
      return -1;
    }
    const diffTypes: string[] = Object.keys(mwgData.data);
    if (diffTypes.indexOf('D_DE') < 0 || !mwgData.data['D_DE'].dataSets) {
      return -1;
    }

    const dDeDataSet = mwgData.data['D_DE'].dataSets.find((dS) => dS.type === batch.productType)
    if (!dDeDataSet || !dDeDataSet.data || dDeDataSet.data.length < 1) {
      return -1;
    }

    let sum: number = 0;
    dDeDataSet.data.forEach((de) => {
      sum += de;
    })

    // mdE*
    return sum / dDeDataSet.data.length;
  }

  public setCustomLabelForMwg(mwgData: IMwgData, cstLabel: string, color: string): IMwgData {
    if (!mwgData || !mwgData.data) {
      return mwgData;
    }

    Object.keys(mwgData.data).forEach((key) => {
      if (mwgData.data[key] && mwgData.data[key].dataSets && mwgData.data[key].dataSets.length > 0) {
        mwgData.data[key].dataSets.forEach((set: Iline) => {
          set.customLabel = cstLabel;
          set.lineColor = color;
        });
      }
    });

    return mwgData;
  }

  public setAmountForRecipeEntries(mixResponse: MixedMeasurementResponse): RecipeEntry[] {
    if (mixResponse.Recipe && mixResponse.Recipe.RecipeEntries && mixResponse.Recipe.RecipeEntries.length > 0
      && mixResponse.Ingredients && mixResponse.Ingredients.length > 0) {
      mixResponse.Recipe.RecipeEntries.forEach((entry) => {
        // save the original amount from the response in order to check for user defined values later
        const ingredient: Ingredient = mixResponse.Ingredients.find((i) => i.Name === entry.Name);
        if (ingredient) {
          entry.Amount = this.round(ingredient.Concentration, 2);
          entry.originalAmount = this.round(ingredient.Concentration, 2);
          entry.originalActive = entry.IsActive;
          entry.IsFixed = (entry.Bound_Upper === entry.Bound_Lower);
          entry.originalFixed = entry.IsFixed;
        } else {
          // only active ingredients are present in the response so in that case we should set original values to default values
          entry.Amount = this.round(0, 2);
          entry.originalAmount = this.round(0, 2);
          entry.originalActive = entry.IsActive;
          entry.IsFixed = (entry.Bound_Upper === entry.Bound_Lower);
          entry.originalFixed = entry.IsFixed;
        }
      })
    }

    //return mixResponse.Recipe.RecipeEntries.sort((a, b) => b.Amount - a.Amount);
    return mixResponse.Recipe.RecipeEntries.sort((a, b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
  }

  public round(input: number, decimals: number): number {
    if (!input) return input;
    return Number(input.toFixed(decimals));
  }

  public getCurrentRecipe(recipeEntries: RecipeEntry[]): Recipe {
    const userTouchedEntries = [];
    if (recipeEntries && recipeEntries.length > 0) {
      recipeEntries.forEach((entry) => {
        // only send fixed values and not active entries to backend all others paste are default active implemented in backend
        if (entry.IsFixed || !entry.IsActive) {
          userTouchedEntries.push(entry);
        }
      })
      return { RecipeEntries: userTouchedEntries } as Recipe;
    }
    return null;
  }

  public uploadVectorFile(vecFile: File, measurementId: number, batchId: number, refBatchId: number, refMeasurementId: number, chartFilters: ChartFilters, recipeMix: Recipe, offset: OffsetDto): Observable<MixedMeasurementResponse> {
    // Returns empty observable if mandatory parameters missing
    if (!vecFile || !measurementId || !batchId) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}`;
    //apiEndpoint += environment.apiVersionPath;
    apiEndpoint += '/vector/batch/' + batchId + '/measurement/' + measurementId + '/uploadVector';

    const fd = new FormData();
    const vecFileBlob = new Blob([vecFile], { type: vecFile.type })
    fd.append('vectorFile', vecFileBlob);
    if (recipeMix) {
      fd.append('recipe', new Blob([JSON.stringify(recipeMix)], { type: 'application/json' }));
    }

    let params = new HttpParams();
    if (chartFilters.tolerance !== undefined) {
      params = params.set('tolerance', chartFilters.tolerance);
    }
    if (chartFilters.labSystem) {
      params = params.set('system', chartFilters.labSystem);
    }
    if (refBatchId) {
      // refBatchId optional param if the tinting target should be a reference batch
      params = params.set('refBatchId', refBatchId);
    }
    if (refMeasurementId) {
      // reference is a dst_cc measurement
      params = params.set('refMeasurementId', refMeasurementId);
    }
    // optional offset only if master tinting in lab mode
    if (!refBatchId && !refMeasurementId && offset) {
      fd.append('offset', new Blob([JSON.stringify(offset)], { type: 'application/json' }));
    }

    const requestOptions = {
      params,
    };

    return this.http.post(apiEndpoint, fd, requestOptions).pipe(
      map((response) => response as MixedMeasurementResponse),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public uploadVectorFileOemLine(vecFile: File, line: string, batchId: number, chartFilters: ChartFilters, recipeMix: Recipe): Observable<MixedMeasurementResponse> {
    // Returns empty observable if mandatory parameters missing
    if (!vecFile || !line || !batchId) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}`;
    //apiEndpoint += environment.apiVersionPath;
    apiEndpoint += '/vector/batch/uploadVector/oemLine?batchId=' + batchId + '&line=' + line;

    const fd = new FormData();
    const vecFileBlob = new Blob([vecFile], { type: vecFile.type })
    fd.append('vectorFile', vecFileBlob);
    if (recipeMix) {
      fd.append('recipe', new Blob([JSON.stringify(recipeMix)], { type: 'application/json' }));
    }

    let params = new HttpParams();
    if (chartFilters.tolerance !== undefined) {
      params = params.set('tolerance', chartFilters.tolerance);
    }
    if (chartFilters.labSystem) {
      params = params.set('system', chartFilters.labSystem);
    }
    const requestOptions = {
      params,
    };

    return this.http.post(apiEndpoint, fd, requestOptions).pipe(
      map((response) => response as MixedMeasurementResponse),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

  public getOffset(refBatchId: number, refMeasurementId: number): Observable<OffsetDto> {
    // Returns empty observable if mandatory parameters missing
    if (!refBatchId) {
      return EMPTY;
    }

    let apiEndpoint = `${this.baseUrl}`;
    apiEndpoint += '/vector/batch/' + refBatchId + '/offset';

    let params = new HttpParams();
    if (refMeasurementId) {
      params = params.set('refMeasurementId', refMeasurementId);
    }
    const requestOptions = {
      params,
    };
    return this.http.get(apiEndpoint, requestOptions).pipe(
      map((response) => response as OffsetDto),
      catchError((error: HttpErrorResponse) => throwError(error || error.error || 'Server error')),
    );
  }

}
