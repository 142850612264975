<colimo-accordion-wrapper
        *ngIf="batch.forecastAvailable"
        [headerTitle]="'dashboard.colorCareInfoHeadline' | translate"
        [showOnlyOnMobile]="true"
>
    <div *ngIf="!isMobile">
        <div class="batch-info colorCare" [ngClass]="'gap'">
            <p class="batch-info-label">ColorCare {{ forecastMeasurementResult }}</p>
        </div>
        <div *ngIf="batch.forecastMeasurements">
            <div class="form-field-group">
                <button
                        class="refresh-button"
                        mat-icon-button
                        *ngIf="batch.forecastMeasurements"
                        matTooltip="{{ 'dashboard.updateForecastMeasurements' | translate }}"
                        [matTooltipPosition]="'above'"
                        [disabled]="isForecastMeasurementLoading"
                        (click)="updateForecastMeasurementsByBatchId()"
                >
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-form-field appearance="fill">
                    <mat-label class="select-label">{{ 'dashboard.selectForecastMeasurement' | translate }}</mat-label>
                    <mat-select
                            name="selectedForecastMeasurement_{{ batch.batchId }}"
                            [(ngModel)]="selectedForecastMeasurement"
                            (selectionChange)="selectedMeasurementChanged($event.value)"
                            [disabled]="isForecastMeasurementLoading"
                            id="forecastmeasurement_{{ batch.batchId }}"
                    >
                        <mat-option *ngFor="let option of batch.forecastMeasurements" [value]="option">{{
                                option.info
                            }}</mat-option>
                    </mat-select>
                    <button
                            *ngIf="selectedForecastMeasurement"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="selectedMeasurementChanged(undefined); $event.stopPropagation()"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="isMobile" class="flex-column">
        <div class="batch-info">
            <p class="batch-info-label">ColorCare {{ forecastMeasurementResult }}</p>
        </div>
        <div *ngIf="batch.forecastMeasurements">
            <div class="form-field-group">
                <button
                        class="refresh-button"
                        mat-icon-button
                        *ngIf="batch.forecastMeasurements"
                        matTooltip="{{ 'dashboard.updateForecastMeasurements' | translate }}"
                        [matTooltipPosition]="'above'"
                        [disabled]="isForecastMeasurementLoading"
                        (click)="updateForecastMeasurementsByBatchId()"
                >
                    <mat-icon>refresh</mat-icon>
                </button>
                <mat-form-field appearance="fill">
                    <mat-label class="select-label">{{ 'dashboard.selectForecastMeasurement' | translate }}</mat-label>
                    <mat-select
                            name="selectedForecastMeasurement_{{ batch.batchId }}"
                            [(ngModel)]="selectedForecastMeasurement"
                            (selectionChange)="selectedMeasurementChanged($event.value)"
                            [disabled]="isForecastMeasurementLoading"
                            id="forecastmeasurement_{{ batch.batchId }}"
                    >
                        <mat-option *ngFor="let option of batch.forecastMeasurements" [value]="option">{{
                                option.info
                            }}</mat-option>
                    </mat-select>
                    <button
                            *ngIf="selectedForecastMeasurement"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="selectedMeasurementChanged(undefined); $event.stopPropagation()"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
    </div>
</colimo-accordion-wrapper>
