<div class="chart-table" *ngIf="table.name && table.dates?.length">
  <table>
    <caption>
      <div class="flex-row">
        <i *ngIf="colorIndicator" class="material-icons color-indicator-margin" [style.color]="colorIndicator">circle</i>
        <span class="table-name">{{ 'missing.name' | translate }}: {{ table.name }}</span>
      </div>
      <span class="table-measurements">
        #{{ 'missing.measurements' | translate }}: <b>{{ table.measurements }}</b></span
      >
      <span class="table-dates">{{ table.dates[0] | date: 'dd.MM.y' }} - {{ table.dates[1] | date: 'dd.MM.y' }}</span>
    </caption>
    <thead>
      <th></th>
      <th *ngFor="let typeLabel of typeLabels; let i = index">
        <span>{{ typeLabel | labelByChartType: tolerance }}</span>
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let row of tableData; let i = index">
        <ng-container *ngIf="selectedAngles[i]">
          <th class="is-left">
            <span>{{ angles[i] }}°</span>
          </th>
          <td *ngFor="let col of row; let j = index" class="{{ 'text-' | kpiLabel: tableColors[i][j] }}">
            <span [ngClass]="{ 'bold-text': tableHighlights[i][j] }">
              {{ col | number: '1.2-2' }}
            </span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>

<div class="chart-table" *ngIf="table.nameCorresponding && table.datesCorresponding?.length">
  <table>
    <caption>
      <span class="table-name">{{ 'missing.name' | translate }}: {{ table.nameCorresponding }}</span>
      <span class="table-measurements">
        #{{ 'missing.measurements' | translate }}: <b>{{ table.measurementsCorresponding }}</b></span
      >
      <span class="table-dates">
        {{ table.datesCorresponding[0] | date: 'dd.MM.y' }} - {{ table.datesCorresponding[1] | date: 'dd.MM.y' }}</span
      >
    </caption>
    <thead>
      <th></th>
      <th *ngFor="let typeLabel of typeLabels; let i = index">
        <span>{{ typeLabel | labelByChartType: tolerance }}</span>
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let row of correspondingTableData; let i = index">
        <ng-container *ngIf="selectedAngles[i]">
          <th class="is-left">
            <span>{{ angles[i] }}°</span>
          </th>
          <td *ngFor="let col of row; let j = index" class="{{ 'text-' | kpiLabel: correspondingTableColors[i][j] }}">
            <span [ngClass]="{ 'bold-text': correspondingTableHighlights[i][j] }">
              {{ col | number: '1.2-2' }}
            </span>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
