import { Injectable } from '@angular/core';

/**
 * Can read and write attributes from/to the browser's local storage.
 * The values may be JSON objects
 */
@Injectable()
export class LocalStorageService {

  getLocalStorage(name: string): any {
    return JSON.parse(localStorage.getItem(name));
  }

  setLocalStorage(obj: { name: string, value: any }): void {
    return localStorage.setItem(obj.name, JSON.stringify(obj.value));
  }

  removeLocalStorage(name: string): void {
    localStorage.removeItem(name);
  }
}
