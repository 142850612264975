import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IBatchForecastMeasurement} from '../../models/batch-forecast-measurement.model';
import {IBatch} from '../../models/batch.model';
import {MobileCheck} from '../../services/mobile-check/mobile-check.service';
import {BatchlineSelection} from '../../models/batchline-selection';
import {Iproject} from '../../models/project.model';

@Component({
  selector: 'colimo-project-batch',
  styleUrls: ['project-batch.component.scss'],
  templateUrl: 'project-batch.component.html',
})
export class ProjectBatchComponent implements OnInit {
  @Input() public project: Iproject;
  @Input() public batch: IBatch;
  @Input() public modelId: string;
  @Input() public projectPage: number;
  @Input() public isColorRoute: boolean;
  @Input() public batchLineSelections: BatchlineSelection[];
  @Input() public showVectorButton: boolean = false;

  @Output() public reload: EventEmitter<any> = new EventEmitter();

  public isMobile: boolean;
  public isExpired: boolean;
  public selectedForecastMeasurement: IBatchForecastMeasurement;
  public batchCommentTooltip: string;

  constructor(private mobileCheck: MobileCheck
  ) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().subscribe((deviceInfo) => {
      this.isMobile = deviceInfo.isMobile;
    });
    this.batchCommentTooltip = this.getBatchCommentToolTip();
    this.checkIsExpired();
  }

  public forecastInit(selectedForecastMeasurementId: number): void {
    setTimeout(() => {
      this.selectedForecastMeasurement = this.batch.forecastMeasurements.find((item: IBatchForecastMeasurement) => {
        return item.id === selectedForecastMeasurementId;
      });
    });
  }

  public onSelectedForecastMeasurementChanged(selectedMeasurement: IBatchForecastMeasurement) {
    this.selectedForecastMeasurement = selectedMeasurement;
  }

  private getBatchCommentToolTip(): string{
    if (!this.batch || !this.batch.batchComments || this.batch.batchComments.length === 0) {
      return '';
    }

    let tooltip = '';
    for (let i = 0; i < this.batch.batchComments.length; i++) {
      tooltip += this.batch.batchComments[i].commentTime + ':' + '\n' +this.batch.batchComments[i].commentText + '\n';
    }
    return tooltip;
  }

  private checkIsExpired(): void {
    if(this.batch && this.batch.expirationDate && this.batch.kpiLines.length > 0) {
      let lmDate = new Date("2000-01-01");
      for(let i= 0; i < this.batch.kpiLines.length; i++) {
        const compDate = new Date(this.batch.kpiLines[i].lastMeasurementDate);
        if(compDate > lmDate){
          lmDate = compDate;
        }
      }
      const expDate = new Date(this.batch.expirationDate);
      this.isExpired = lmDate > expDate;
    }
  }
}
