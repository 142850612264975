/* eslint-disable max-lines */
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';
import { StoreModule } from '@ngrx/store';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  AppInfoModule,
  AuthModule,
  AuthorizationInterceptorService,
  NotAuthenticatedInterceptorService
} from 'colimo-ui-library';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlienCheckFilterComponent } from './components/alien-check/alien-check-filter/alien-check-filter.component';
import { AlienCheckStatusTableComponent } from './components/alien-check/alien-check-status-table/alien-check-status-table.component';
import { BackendInfoComponent } from './components/backend-info/backend-info.component';
import { BatchListWithTooltipComponent } from './components/batch-list-with-tooltip/batch-list-with-tooltip.component';
import { CarInfoBoxComponent } from './components/car-info-box/car-info-box.component';
import { CarReportBumperStatisticsComponent } from './components/car-report/car-report-bumper-statistics/car-report-bumper-statistics.component';
import { CarReportConfigurationComponent } from './components/car-report/car-report-configuration/car-report-configuration.component';
import { CarReportInformationComponent } from './components/car-report/car-report-information/car-report-information.component';
import { CarReportLabchStatisticsComponent } from './components/car-report/car-report-labch-statistics/car-report-labch-statistics.component';
import { CarReportMwgChartComponent } from './components/car-report/car-report-mwg-chart/car-report-mwg-chart.component';
import { CarReportOverviewComponent } from './components/car-report/car-report-overview/car-report-overview.component';
import { CarReportStatisticsComponent } from './components/car-report/car-report-statistics/car-report-statistics.component';
import { ChartDegGraphicComponent } from './components/chart-deg/chart-deg-graphic.component';
import { ChartDegComponent } from './components/chart-deg/chart-deg.component';
import { FDGchartListComponent } from './components/chart-fdg/fdg-chart-list.component';
import { FDGchartComponent } from './components/chart-fdg/fdg-chart.component';
import { FOGchartListComponent } from './components/chart-fog/fog-chart-list.component';
import { FOGchartComponent } from './components/chart-fog/fog-chart.component';
import { LPGchartListComponent } from './components/chart-lpg/lpg-chart-list.component';
import { LPGchartComponent } from './components/chart-lpg/lpg-chart.component';
import { MWGchartListComponent } from './components/chart-mwg/mwg-chart-list.component';
import { MWGchartComponent } from './components/chart-mwg/mwg-chart.component';
import { ChartTableComponent } from './components/chart-table/chart-table.component';
import { ColorCardComponent } from './components/color-card/color-card.component';
import { CustomerHeaderComponent } from './components/customer-header/customer-header.component';
import { DashboardControlsComponent } from './components/dashboard';
import { ForecastComponent } from './components/forecast/forecast.component';
import { FrontendInfoComponent } from './components/frontend-info/frontend-info.component';
import { KpiLineComponent } from './components/kpi/kpi-line.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { TrendAnalysisResultComponent } from './components/kpi/trend-analysis-result/trend-analysis-result.component';
import { MappedMeasurementsComponent } from './components/measurements/mapped-measurements/mapped-measurements.component';
import { ProjectBatchComponent } from './components/project/project-batch.component';
import { ProjectHeaderComponent } from './components/project/project-header.component';
import { AverageKpisComponent } from './components/report/average-kpis/average-kpis.component';
import { FilterCheckboxesPartComponent } from './components/report/filter-checkboxes-part/filter-checkboxes-part.component';
import { KpiReportFilterComponent } from './components/report/kpi-report-filter/kpi-report-filter.component';
import { KpiReportTableComponent } from './components/report/kpi-report-table/kpi-report-table.component';
import { SearchComponent } from './components/search/search.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CollapsedSidenavComponent } from './components/sidenav/collapsed-sidenav/collapsed-sidenav.component';
import { ExtendedSidenavComponent } from './components/sidenav/extended-sidenav/extended-sidenav.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AlienCheckPageComponent } from './pages/alien-check-page/alien-check-page.component';
import { AppInfoComponent } from './pages/app-info/app-info.component';
import { BatchPageComponent } from './pages/batch/batch-page.component';
import { CarReportComponent } from './pages/car-report/car-report.component';
import { ColorDashboardComponent } from './pages/color-dashboard/color-dashboard.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { CustomerCardsComponent } from './pages/customer-cards/customer-cards.component';
import { ErrorPageComponent } from './pages/error/error-page.component';
import { LoginModule } from './pages/login/login.module';
import { ModelDashboardComponent } from './pages/model-dashboard/model-dashboard.component';
import { PaintlineDashboardComponent } from './pages/paintline-dashboard/paintline-dashboard.component';
import { PrivacyPolicyDeComponent } from './pages/privacy-policy/privacy-policy-de/privacy-policy-de.component';
import { PrivacyPolicyEnComponent } from './pages/privacy-policy/privacy-policy-en/privacy-policy-en.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProjectPageComponent } from './pages/project/project-page.component';
import { ReportPageComponent } from './pages/report/report-page.component';
import { AlignedProductsPipe } from './pipes/aligned-products/aligned-products.pipe';
import { AreTinterAdditionsOnPagePipe } from './pipes/are-tinter-additions-on-page/are-tinter-additions-on-page.pipe';
import { ArrayToPipeListPipe } from './pipes/array-to-pipe-list/array-to-pipe-list.pipe';
import { ComponentStatusCssClassPipe } from './pipes/component-status-css-class/component-status-css-class.pipe';
import { ComponentStatusPositionCssClassPipe } from './pipes/component-status-position-css-class/component-status-position-css-class.pipe';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { DeltaValueOfAnglePipe } from './pipes/delta-value-of-angle/delta-value-of-angle.pipe';
import { DeltaValuesCssClassPipe } from './pipes/delta-values-css-class/delta-values-css-class.pipe';
import { FdgLegendCssPipe } from './pipes/fdg-legend-css/fdg-legend-css.pipe';
import { FilterValuesPipe } from './pipes/filter-values/filter-values.pipe';
import { LineNamePipe } from './pipes/line-name/line-name.pipe';
import { ProbabilityCssClassPipe } from './pipes/probability-css-class/probability-css-class.pipe';
import { ProductBatchesPipe } from './pipes/product-batches/product-batches.pipe';
import { ProductCountPipe } from './pipes/product-count/product-count.pipe';
import { SitesNamesPipe } from './pipes/site-names/sites-names.pipe';
import { TabIlluminantPipe } from './pipes/tab-illuminant/tab-illuminant.pipe';
import { TinterAdditionTooltipPipe } from './pipes/tinter-addition-tooltip/tinter-addition-tooltip.pipe';
import { WarningColorCssPipe } from './pipes/warning-color-css/warning-color-css.pipe';
import { WarningIconColorCssPipe } from './pipes/warning-icon-color-css/warning-icon-color-css.pipe';
import { WarningsCountPipe } from './pipes/warnings-count/warnings-count.pipe';
import { reducers } from './reducers';
import { HeaderInterceptorService } from './services/header-interceptor/header-interceptor.service';
import { LocaleService } from './services/locale/locale.service';
import { ThemeService } from './services/theme/theme.service';
import { PageHeaderComponent } from './shared/components/page-header/page-header.component';
import { SharedModule } from './shared/shared.module';
import { MobileSidenavComponent } from './components/sidenav/mobile-sidenav/mobile-sidenav.component';
import { MultiBatchPageComponent } from './pages/multi-batch/multi-batch-page/multi-batch-page.component';
import { NotificationPageComponent } from './pages/notification-page/notification-page.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AlertMaxLevelPipe } from './pipes/alert-max-level/alert-max-level.pipe';
import {MatSortModule} from '@angular/material/sort';
import {ChartVidComponent} from "./components/chart-vid/chart-vid.component";
import {MatLuxonDateModule} from "@angular/material-luxon-adapter";
import { VectorTintingPageComponent } from './pages/vector-tinting/vector-tinting-page/vector-tinting-page.component';
import { ForecastMeasurementComponent } from './components/forecast-measurement/forecast-measurement.component';
import {CloudStorageModule} from '@colorcare/ngx-cloud-storage';
import {NgxUtilsModule} from '@colorcare/ngx-utils';
import localeEn from "@angular/common/locales/en"
import localeDe from "@angular/common/locales/de"
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {MatGridListModule} from "@angular/material/grid-list";
import { OffsetDialogComponent } from './pages/vector-tinting/offset-dialog/offset-dialog.component';
import { VectorPasteAmountFilterPipe } from './pipes/vector-paste-amount-filter/vector-paste-amount-filter.pipe';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    return '{' + params.key + '}';
  }
}

@NgModule({
  declarations: [
  AppComponent,
  SearchComponent,
  ErrorPageComponent,
  BatchPageComponent,
  ProjectHeaderComponent,
  ProjectPageComponent,
  ProjectBatchComponent,
  KpiComponent,
  KpiLineComponent,
  ColorDashboardComponent,
  DashboardControlsComponent,
  MWGchartComponent,
  FDGchartComponent,
  FOGchartComponent,
  LPGchartComponent,
  MWGchartListComponent,
  FDGchartListComponent,
  LPGchartListComponent,
  ChartTableComponent,
  FOGchartListComponent,
  ForecastComponent,
  ChartDegComponent,
  ChartDegGraphicComponent,
  ReportPageComponent,
  KpiReportFilterComponent,
  KpiReportTableComponent,
  FilterCheckboxesPartComponent,
  AverageKpisComponent,
  FilterValuesPipe,
  DateFormatPipe,
  AppInfoComponent,
  MappedMeasurementsComponent,
  BackendInfoComponent,
  FrontendInfoComponent,
  FdgLegendCssPipe,
  WarningColorCssPipe,
  WarningsCountPipe,
  SitesNamesPipe,
  WarningIconColorCssPipe,
  CustomerHeaderComponent,
  ProductCountPipe,
  DeltaValueOfAnglePipe,
  ComponentStatusCssClassPipe,
  CarInfoBoxComponent,
  AreTinterAdditionsOnPagePipe,
  AlienCheckPageComponent,
  AlienCheckFilterComponent,
  AlienCheckStatusTableComponent,
  ProbabilityCssClassPipe,
  ProductBatchesPipe,
  BatchListWithTooltipComponent,
  LineNamePipe,
  CarReportComponent,
  CarReportConfigurationComponent,
  CarReportInformationComponent,
  CarReportOverviewComponent,
  CarReportStatisticsComponent,
  ComponentStatusPositionCssClassPipe,
  CarReportBumperStatisticsComponent,
  CarReportLabchStatisticsComponent,
  DeltaValuesCssClassPipe,
  CarReportMwgChartComponent,
  SidenavComponent,
  ExtendedSidenavComponent,
  CollapsedSidenavComponent,
  SettingsComponent,
  PageHeaderComponent,
  ModelDashboardComponent,
  ColorCardComponent,
  PaintlineDashboardComponent,
  ArrayToPipeListPipe,
  CustomerCardsComponent,
  PrivacyPolicyEnComponent,
  PrivacyPolicyDeComponent,
  PrivacyPolicyComponent,
  ContactPageComponent,
  TabIlluminantPipe,
  AlignedProductsPipe,
  TinterAdditionTooltipPipe,
  TrendAnalysisResultComponent,
  MobileSidenavComponent,
  MultiBatchPageComponent,
  NotificationPageComponent,
  AlertMaxLevelPipe,
  VectorTintingPageComponent,
  ForecastMeasurementComponent,
  OffsetDialogComponent,
  VectorPasteAmountFilterPipe,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatDividerModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatMenuModule,
    MatExpansionModule,
    MatBadgeModule,
    MatTabsModule,
    MatRippleModule,
    MatDialogModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    ClarityModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      useDefaultLang: false,
    }),
    SharedModule.forRoot(),
    BsDatepickerModule.forRoot(),
    LoginModule,
    AuthModule.forRoot({
      baseUrl: environment.apiBasePath,
      baseAuthPath: environment.baseAuthPath,
      minimalValidationTimeLeftInSeconds: environment.minimalValidationTimeLeftInSeconds,
      loginRoute: environment.loginRoute,
      routeAfterLogin: environment.routeAfterLogin,
      debug: false,
      colorCareRoute: environment.colorCareRoute,
      colorCareLoginUrl: environment.colorCareLoginUrl,
    }),
    CloudStorageModule.forRoot({
      cloudStorageServiceUrl: environment.colorCareCloudStorageServiceUrl,
      userManagementServiceUrl: environment.colorCareEvoUserUrl
    }),
    NgxUtilsModule.forRoot({
      i18n: {
        translationFolders: ["./assets/i18n/"], /*, "./assets/ngx-cloud-storage/i18n/" */
        languages: [
          {id: "de", data: localeDe, defaults: {dateFormat: "medium"}}, //urlMatcher: new RegExp(/^\/de(\/?|\/.*|\?.+=.*)$/)
          {id: "en", data: localeEn, defaults: {dateFormat: "medium"}}, //urlMatcher: new RegExp(/^\/en(\/?|\/.*|\?.+=.*)$/),
        ],
        defaults: {
          language: "en",
        },
      },
    }),
    AppInfoModule.forRoot({
      backendUrl: environment.apiBasePath + environment.apiUserPath + '/' + environment.backendInfo,
    }),
    MatCheckboxModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    ChartVidComponent,
    MatLuxonDateModule,
    CdkDropList,
    CdkDrag,
    MatGridListModule
  ],
  providers: [
// expose our Services and Providers into Angular's dependency injection
  DatePipe,
  ProductBatchesPipe,
  {
  provide: HTTP_INTERCEPTORS,
  useClass: HeaderInterceptorService,
  multi: true,
  },
  {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthorizationInterceptorService,
  multi: true,
  },
  {
  provide: HTTP_INTERCEPTORS,
  useClass: NotAuthenticatedInterceptorService,
  multi: true,
  },
  ThemeService,
  ],
  bootstrap: [AppComponent],
  })
export class AppModule {
  constructor(private localeService: LocaleService) {
    this.localeService.defineUsedLocales();
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  // eslint-disable-next-line max-lines
}
