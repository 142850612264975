import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import * as Chart from 'chart.js';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ComponentGroups} from 'src/app/models/viewmodels/component-groups.model';
import {ChartDataService} from 'src/app/services/chart-data/chart-data.service';
import {ChartFilters} from '../../models/chart-filters.model';
import {IDataSets, Iline, IMwgData} from '../../models/chart-mwg.model';
import {ModelsComponents} from '../../models/models-components.model';
import {ExportClipboardParameter} from '../../services/export-to-clipboard/export-clipboard-parameter';
import {ImwgDataValidatorService} from '../../services/imwg-data-validator/imwg-data-validator.service';
import {Scale} from './scale';
import {ChartDefaults} from '../../shared/enums/chart-defaults.enum';
import {VectorService} from "../../services/vector/vector.service";

/**
 * Displays a list of MWG charts (MittelWertGrafik), contains chart types and default labels
 */
@Component({
  selector: 'colimo-mwg-chart-list',
  styleUrls: ['mwg-chart-list.component.scss'],
  templateUrl: 'mwg-chart-list.component.html',
})
export class MWGchartListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public showChartFilters: boolean;
  @Input() public showChartTable: boolean;
  @Input() public isVectorPage: boolean = false;
  @Input() public vectorMeasurementId: number;
  @Input() public chartFilters: ChartFilters;
  @Input() public selectedAngles: boolean[];
  @Input() public activeComponents: ComponentGroups;
  @Input() public modelsComponents: ModelsComponents;
  @Input() public batchId: number;
  @Input() public lineId: string;
  @Output() public chartFiltersChange: EventEmitter<ChartFilters> = new EventEmitter();
  @Output() public chartDataChange: EventEmitter<IMwgData> = new EventEmitter();
  @Output() public tolerancesChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() public mwgChartLoading: EventEmitter<boolean> = new EventEmitter();

  public chartData: IMwgData;
  public isLoading: boolean;
  public exportClipboardParameter: ExportClipboardParameter;

  // Only 5 chart types
  public mwgChartTypes = ['DL', 'DA', 'DB', 'DC', 'DH'];
  // API response
  // Default labels
  public lineChartLabels: string[] = [];
  // Default scale
  public scale: Scale = {
    max: 1.5,
    min: -1.5,
  };
  public dataAvailable: boolean;

  private stop$: Subject<boolean> = new Subject();

  constructor(private imwgDataValidatorService: ImwgDataValidatorService,
              private chartDataService: ChartDataService,
              private vectorService: VectorService) {}

  public ngOnInit(): void {
    // Declare options for toleranceLine Plugin
    Chart.defaults.set(ChartDefaults.TOLERANCE_OPTIONS, {
      color: ChartDefaults.DEFAULT_TOLERANCE_LINE_COLOR,
      lineWidth: 1,
      dashed: true,
      asSquare: false,
      xOffset: 42,
      yOffset: 25,
    });
    this.getMwgData();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartFilters && !changes.chartFilters.firstChange) {
      if (this.chartFilters) {
        this.getMwgData();
      }
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  private getMwgData(): void {
    this.setLoading(true);
    if (this.isVectorPage) {
      this.vectorService.getMwgDataListObservable().pipe(takeUntil(this.stop$)).subscribe({
        next: (mwgDataList: IMwgData[]) => {
          if (mwgDataList && mwgDataList.length > 0) {
            const combinedData = this.combineMultipleMwgChartData(mwgDataList);
            this.processMwgData(combinedData);
            this.chartDataChange.emit(combinedData);
          } else {
            // TODO this seems not good
            this.processMwgData({} as IMwgData);
            this.chartDataChange.emit({} as IMwgData);
          }
        }, error: () => {
          // do nothing
        }
      })
      //this.dataAvailable = true;
      this.setLoading(false);
    }
    else if (!this.batchId || !this.lineId) {
      this.chartDataService.getMwgData(this.chartFilters, this.modelsComponents).pipe(takeUntil(this.stop$)).subscribe((iMwgData: IMwgData) => {
        this.processMwgData(iMwgData);
        this.chartDataChange.emit(iMwgData);
      });
    } else {
      // create a deep copy of chart filters for model/comp/tolerance filter etc. and manipulate only the batch and line Id
      const chartFiltersForMultiBatch: ChartFilters = JSON.parse(JSON.stringify(this.chartFilters));
      chartFiltersForMultiBatch.batchId = this.batchId;
      chartFiltersForMultiBatch.lineId = this.lineId;
      this.chartDataService.getMwgData(chartFiltersForMultiBatch, this.modelsComponents).pipe(takeUntil(this.stop$)).subscribe((iMwgData: IMwgData) => {
        this.processMwgData(iMwgData);
        this.chartDataChange.emit(iMwgData);
      });
    }
    /*
    // this might be needed in the future multiple batches should be displayed in one chart
    const observables: Observable<IMwgData>[] = [];

    if (this.chartFilters.batchIds && this.chartFilters.lineIds) {
      const batchIds: string[] = this.chartFilters.batchIds.split(',');
      const lineIds: string[] = this.chartFilters.lineIds.split(',');
      // create a deep copy of chart filters for model/comp/tolerance filter etc.
      const chartFiltersForMultiBatch: ChartFilters = JSON.parse(JSON.stringify(this.chartFilters));
      for (let i = 0; i < batchIds.length; i++) {
        chartFiltersForMultiBatch.batchId = parseInt(batchIds[i]);
        chartFiltersForMultiBatch.lineId = lineIds[i];
        observables.push(this.chartDataService.getMwgData(chartFiltersForMultiBatch, this.modelsComponents));
      }
    } else {
      observables.push(this.chartDataService.getMwgData(this.chartFilters, this.modelsComponents));
    }

    forkJoin(observables).pipe(takeUntil(this.stop$)).subscribe({next: (iMwgDatas: IMwgData[]) => {
      //const currentMwgData = this.combineMultipleMwgChartData(iMwgDatas);
      this.processMwgData(iMwgDatas[0]);
      this.chartDataChange.emit(iMwgDatas[0]);
    }});*/
  }

  // this might be needed in the future multiple batches should be displayed in one chart
  private combineMultipleMwgChartData(chartDatas: IMwgData[]): IMwgData {
    if (!chartDatas || chartDatas.length < 1) {
      return null;
    }

    const mwgForValues: IMwgData = JSON.parse(JSON.stringify(chartDatas[0])); // DEEP COPY

    const combined: IMwgData = {} as IMwgData;
    combined.batchId = mwgForValues.batchId;
    combined.batchNumber = mwgForValues.batchNumber;
    combined.batchType = mwgForValues.batchType;
    combined.lineNumber = mwgForValues.lineNumber;
    combined.colorSpace = mwgForValues.colorSpace;
    combined.tolerances = mwgForValues.tolerances;
    combined.lightSource = mwgForValues.lightSource;
    combined.angles = mwgForValues.angles;
    let minScale = -2.0;
    let maxScale = 2.0;
    let minScaleTol = -1.0;
    let maxScaleTol = 1.0;
    for (let t = 0; t < chartDatas.length; t++) {
      if (chartDatas[t].scale && chartDatas[t].scale.length > 0) {
        if (chartDatas[t].scale[0] < minScale) {
          minScale = chartDatas[t].scale[0];
        }
        if (chartDatas[t].scale[1] > maxScale) {
          maxScale = chartDatas[t].scale[1];
        }
      }
      if (chartDatas[t].scaleTolerance && chartDatas[t].scaleTolerance.length > 0) {
        if (chartDatas[t].scaleTolerance[0] < minScaleTol) {
          minScaleTol = chartDatas[t].scaleTolerance[0];
        }
        if (chartDatas[t].scaleTolerance[1] > maxScaleTol) {
          maxScaleTol = chartDatas[t].scaleTolerance[1];
        }
      }
    }

    combined.scale = [minScale, maxScale];
    combined.scaleTolerance = [minScaleTol, maxScaleTol];

    combined.data = {} as IDataSets;

    for (let x = 0; x < this.mwgChartTypes.length; x++) {
      const chartType = this.mwgChartTypes[x];
      for (let i = 0; i < chartDatas.length; i++) {
        if (chartDatas[i] && chartDatas[i].data && chartDatas[i].data[chartType] && chartDatas[i].data[chartType].dataSets) {
          for (let j = 0; j < chartDatas[i].data[chartType].dataSets.length; j++) {
            if (chartDatas[i].data[chartType].dataSets[j].data && chartDatas[i].data[chartType].dataSets[j].data.length > 0) {
              if (!combined.data[chartType] || !combined.data[chartType].dataSets) {
                combined.data[chartType] = { dataSets: [], chQuadrant: chartDatas[i].data[chartType].chQuadrant };
              }
              const set: Iline = {
                type: chartDatas[i].data[chartType].dataSets[j].type,
                customLabel: chartDatas[i].data[chartType].dataSets[j].customLabel ? chartDatas[i].data[chartType].dataSets[j].customLabel : chartDatas[i].data[chartType].dataSets[j].type,
                //data: chartDatas[i].data[chartType].dataSets[j].data, // changed to evaluate angles because sometimes a theory has less angles than the reference
                data: this.evaluateIlineDataForAngles(combined, chartDatas[i], chartType, j),
                colors: chartDatas[i].data[chartType].dataSets[j].colors,
                highlights: chartDatas[i].data[chartType].dataSets[j].highlights,
                lineColor: chartDatas[i].data[chartType].dataSets[j].lineColor
              }
              combined.data[chartType].dataSets.push(set);
            }
          }
        }
      }
    }
    // console.log(JSON.stringify(combined));
    return combined;
  }

  private evaluateIlineDataForAngles(combined: IMwgData, current: IMwgData, chartType: string, dataSetIndex: number): number[] {
    const combinedAngles: string[] = combined.angles;
    const currentAngles: string[] = current.angles;
    const data: number[] = [];
    let currentDataIndex: number = 0;
    for (let i = 0; i < combinedAngles.length; i++) {
      if (currentAngles.includes(combinedAngles[i])) {
        data.push(current.data[chartType].dataSets[dataSetIndex].data[currentDataIndex]);
        currentDataIndex++;
      } else {
        data.push(undefined);
      }
    }
    return data;
  }

  private processMwgData(iMwgData: IMwgData): void {
    const TIMEOUT_IN_MILLISECONDS = 30000;
    if (iMwgData && iMwgData.batchNumber && iMwgData.data) {
      this.chartData = iMwgData;
      this.buildExportClipboardParameter(iMwgData);
    }

    // Set global Chart options for toleranceLine Plugin
    Chart.defaults.set(ChartDefaults.TOLERANCE_SHOW, { show: iMwgData.tolerances });
    Chart.defaults.set(ChartDefaults.TOLERANCE_SCALE, iMwgData.scaleTolerance ? iMwgData.scaleTolerance : []);

    if (this.chartFilters.tolerance === null || this.chartFilters.tolerance === undefined) {
      this.tolerancesChanged.emit(iMwgData.tolerances);
    }

    if (!this.imwgDataValidatorService.isValid(iMwgData)) {
      this.dataAvailable = false;
    } else {
      this.dataAvailable = true;
      if (iMwgData.scale) {
        this.scale.min = iMwgData.scale[0];
        this.scale.max = iMwgData.scale[1];
      }

      if (iMwgData.angles) {
        this.lineChartLabels = iMwgData.angles;
        // BCH-568: Displaying all available angles as default
        if (this.selectedAngles.length === 0 && this.lineChartLabels.length !== 0) {
          this.selectedAngles = new Array(this.lineChartLabels.length).fill(true);
          this.chartFiltersChange.emit(this.chartFilters);
        }
      }
      this.setLoading(false);
    }

    setTimeout(() => {
      this.setLoading(false);
    }, TIMEOUT_IN_MILLISECONDS);
  }

  private buildExportClipboardParameter(response: IMwgData): void {
    this.exportClipboardParameter = {
      batchId: response.batchId,
      line: response.lineNumber,
    };
  }

  private setLoading(loading: boolean): void {
    this.isLoading = loading;
    this.mwgChartLoading.emit(loading);
  }
}
