import { Injectable } from '@angular/core';
import {ColorDataService} from "../color-data/color-data.service";
import {VectorHistory} from "../../models/vector-api/vector-history.model";
import {ChartFilters} from "../../models/chart-filters.model";
import {IMwgData} from "../../models/chart-mwg.model";
import {VectorTheory} from "../../models/vector-api/vector-theory.model";
import {IBatch} from "../../models/batch.model";
import {VectorService} from "./vector.service";
import {Iproject} from "../../models/project.model";
import {IBatchForecastMeasurement} from "../../models/batch-forecast-measurement.model";
import {IKpiLine} from "../../models/kpi.model";
import {MixedMeasurementResponse} from "../../models/vector-api/mixed-measurement-response.model";
import {RecipeEntry} from "../../models/vector-api/recipe-entry.model";
import {OffsetHistoryService} from "./offset-history.service";

@Injectable({
  providedIn: 'root',
})
export class VectorHistoryService {

  // prefix for vector history in storage
  public static STORAGE_PREFIX: string = 'VECTOR-';

  // tinting targets for QC ref asc file, ref batch measurement first with ccng file type 3 or retain sample ccng file type 2 measurement
  public static TINTING_TARGET_REF: string = 'REF';
  public static TINTING_TARGET_BATCH: string = 'BATCH';
  public static TINTING_TARGET_RS: string = 'RS';

  // lab tinting modes
  public static LAB_TINTING_MODE_MASTER: string = 'MASTER';
  public static LAB_TINTING_MODE_BATCH: string = 'BATCH';

  // lab tinting master offset
  public static LAB_TINTING_OFFSET_BATCH: string = 'BATCH';
  public static LAB_TINTING_OFFSET_RS: string = 'RS';

  // possible mwg filter indicators
  private TOL_ON_LAB: string = 'TolOnLab';
  private TOL_OFF_LAB: string = 'TolOffLab';
  private TOL_ON_LCH: string = 'TolOnLch';
  private TOL_OFF_LCH: string = 'TolOffLch';

  // storage for storing vector history -> session or local storage can be used session storage will be preferred
  private storage: Storage;

  constructor(private readonly colorDataService: ColorDataService,
              private readonly vectorService: VectorService) {
    // switch between session or localstorage (session storage preferred by carlos)
    // this.storage = localStorage; // is persisted and not deleted unless user deletes it
    this.storage = sessionStorage; // clears when browser tab is closed
  }

  public getColorForHistory(index: number, currentHistory?: VectorHistory): string {
    //let color = 'lightgray';
    // reference color is blue - first color from multi batch colors
    if (index === 0) {
      return this.colorDataService.getSimpleMultiBatchColors()[0];
    }

    return this.getDistinctColorForHistory(this.colorDataService.getChartColors(), index, currentHistory.vectorTheories);
  }

  private getDistinctColorForHistory(chartColors: string[], index: number, theories: VectorTheory[]): string {
    let color = 'lightgray';

    if(index >= chartColors.length) {
      index = index % chartColors.length;
    }

    if (chartColors[index]) {
      color = chartColors[index];
    }

    // check if color is already present
    const presentColors: string[] = [];
    for (let i = 0; i < theories.length; i++) {
      presentColors.push(theories[i].color);
    }

    // try to find the first color that is not present
    if (presentColors.indexOf(color) >= 0) {
      for (let j = 0; j < chartColors.length; j++) {
        if (presentColors.indexOf(chartColors[j]) < 0) {
          color = chartColors[j];
          break;
        }
      }
    }

    return color;
  }

  public addMwgRef(historyIdentifier: string, chartFilters: ChartFilters, mwgData: IMwgData, currentProject: Iproject, currentBatch: IBatch,
                   currentMeasurement: IBatchForecastMeasurement, currentKpiLine: IKpiLine,
                   tintingTarget?: string): IMwgData {
    if (!historyIdentifier) {
      return mwgData;
    }

    // enhance mwg data with mdE*
    const mwgRefData = this.vectorService.setCustomLabelForMwg(mwgData, mwgData.table.name, this.getColorForHistory(0));
    const mDE = this.vectorService.round(this.vectorService.calculateMdE(mwgData, currentBatch), 2);
    if (mDE >= 0) {
      mwgRefData.table.name += ' - mdE* ' + mDE;
    }

    // if no history present create default for the given filters
    let history = this.getFromStorage(historyIdentifier);
    if (!history) {
      history = {
        identifier: historyIdentifier,
        project: currentProject,
        batch: currentBatch,
        measurement: currentMeasurement,
        kpiLine: currentKpiLine,
        vectorFile: null,
        mwgRef: {},
        vectorTheories: []
      } as VectorHistory
    }

    const isLab: boolean = mwgRefData.colorSpace === 'LAB';
    const tols: boolean = mwgRefData.tolerances;
    history.mwgRef[this.getIndicatorMwgFilter(tols, isLab)] = mwgRefData;
    this.saveToStorage(historyIdentifier, history);
    return mwgRefData;
  }

  public addTheoryToHistory(history: VectorHistory, currentFilters: ChartFilters, response: MixedMeasurementResponse, currentRecipeEntries: RecipeEntry[], colorForHistory: string, mdE: number, selectedTarget?: string): VectorHistory {
    const theory: VectorTheory = {
      chartFilters: currentFilters,
      mixResponse: response,
      created: response.Created,
      selected: true,
      color: colorForHistory,
      info: response.Status,
      recipeEntries: currentRecipeEntries,
      tintingTarget: selectedTarget,
      mwgTheory: {},
      theoryMdE: {},
    } as VectorTheory;

    const isLab: boolean = currentFilters.labSystem === 'LAB';
    const tols: boolean = currentFilters.tolerance;

    theory.mwgTheory[this.getIndicatorMwgFilter(tols, isLab)] = response.AvgView;
    if (mdE >= 0) {
      theory.theoryMdE[this.getIndicatorMwgFilter(tols, isLab)] = mdE;
      theory.displayedMdE = mdE;
    }

    history.vectorTheories.push(theory);
    this.saveToStorage(history.identifier, history);
    return history;
  }

  public updateTheoryMwg(theory: VectorTheory, currentFilters: ChartFilters, mwgData: IMwgData, currentBatch: IBatch, tintingTarget?: string): VectorTheory {
    if (!theory || !mwgData || !(theory.tintingTarget === tintingTarget)) {
      return theory;
    }

    const tableName = 'theory-' + theory.mixResponse.Created;
    mwgData = this.vectorService.setCustomLabelForMwg(mwgData, tableName, theory.color);
    mwgData.table.name = tableName;

    const isLab: boolean = currentFilters.labSystem === 'LAB';
    const tols: boolean = currentFilters.tolerance;
    const mdE = this.vectorService.round(this.vectorService.calculateMdE(mwgData, currentBatch), 2);
    if (mdE >= 0) {
      mwgData.table.name += ' - mdE* ' + mdE;
      theory.theoryMdE[this.getIndicatorMwgFilter(tols, isLab)] = mdE;
      theory.displayedMdE = mdE;
    } else {
      // dont display mdE that might be inconsistent
      theory.displayedMdE = null;
    }
    theory.mwgTheory[this.getIndicatorMwgFilter(tols, isLab)] = mwgData;
    return theory;
  }

  public getMwgRef(identifier: string, chartFilters: ChartFilters, tintingTarget?: string): IMwgData {
    const history: VectorHistory = this.getFromStorage(identifier);
    if (!history) {
      return null;
    }
    return this.getMwgRefForFilters(tintingTarget, chartFilters, history);
  }

  public getMwgTheoryForFilters(theory: VectorTheory, chartFilters: ChartFilters, tintingTarget?: string): IMwgData {
    if (!theory || !theory.mwgTheory || !(theory.tintingTarget === tintingTarget)) {
      return null;
    }
    const isLab: boolean = chartFilters.labSystem === 'LAB';
    const tols: boolean = chartFilters.tolerance;
    return theory.mwgTheory[this.getIndicatorMwgFilter(tols, isLab)]; // return theory for filters which might be undefined which is expected
  }

  public getMwgTheoriesForHistory(history: VectorHistory, chartFilters: ChartFilters, tintingTarget?: string): IMwgData[] {
    if (!history) {
      return [];
    }
    const mwgTheories: IMwgData[] = [];
    if (history.vectorTheories && history.vectorTheories.length > 0) {
      const isLab: boolean = chartFilters.labSystem === 'LAB';
      history.vectorTheories.forEach((t) => {
        if (t.tintingTarget === tintingTarget && t.mwgTheory[this.getIndicatorMwgFilter(chartFilters.tolerance, isLab)]) {
          t.displayedMdE = t.theoryMdE[this.getIndicatorMwgFilter(chartFilters.tolerance, isLab)];
          mwgTheories.push(t.mwgTheory[this.getIndicatorMwgFilter(chartFilters.tolerance, isLab)]);
        }
      });
    }
    return mwgTheories;
  }

  public getVectorHistory(identifier: string): VectorHistory {
    return this.getFromStorage(identifier);
  }

  public deleteSingleVectorHistory(history: VectorHistory, theoryToDelete: VectorTheory): VectorHistory {
    if (!history || !history.vectorTheories) {
      return history;
    }
    const vectorTheoryToDelete = history.vectorTheories.find((t) => {
      return t.created === theoryToDelete.created;
    });
    const index = history.vectorTheories.indexOf(vectorTheoryToDelete);
    if (index === -1 ) {
      return history;
    }

    history.vectorTheories.splice(index, 1);
    this.saveToStorage(history.identifier, history);

    return history;
  }

  public deleteAllVectorHistory(): void {
    const historyKeysToRemove: string[] = [];
    // remove all vector history from storage
    for (let i = 0; i < this.storage.length; i++) {
      if (this.storage.key(i).startsWith(VectorHistoryService.STORAGE_PREFIX) ||
        this.storage.key(i).startsWith(OffsetHistoryService.OFFSET_STORAGE_PREFIX)) {
        historyKeysToRemove.push(this.storage.key(i));
      }
    }
    if (historyKeysToRemove.length > 0) {
      for ( let j = 0; j < historyKeysToRemove.length; j++) {
        this.deleteFromStorage(historyKeysToRemove[j]);
      }
    }
  }

  private getMwgRefForFilters(tintingTarget: string, chartFilters: ChartFilters, history: VectorHistory): IMwgData {
    const isLab: boolean = chartFilters.labSystem === 'LAB';
    // on a fresh reload tols of chartFilters are undefined => get first mwg data that matches
    const tolsUndefined: boolean = chartFilters.tolerance === undefined;
    if (tolsUndefined) {
      return this.getMwgRefForUndefinedTolerances(tintingTarget, isLab, history);
    }

    const tols: boolean = chartFilters.tolerance;
    return history.mwgRef[this.getIndicatorMwgFilter(tols, isLab)];
  }

  private getMwgRefForUndefinedTolerances(tintingTarget: string, isLab: boolean, history: VectorHistory): IMwgData {
    // check if tols on for tinting target is present otherwise return tols off which might also be undefined but that is expected
    // currently look for tols off first then if not present tols on
    //return history.mwgRef[this.getIndicatorMwgFilter(true, isLab)] ? history.mwgRef[this.getIndicatorMwgFilter(true, isLab)] : history.mwgRef[this.getIndicatorMwgFilter(false, isLab)];
    return history.mwgRef[this.getIndicatorMwgFilter(false, isLab)] ? history.mwgRef[this.getIndicatorMwgFilter(false, isLab)] : history.mwgRef[this.getIndicatorMwgFilter(true, isLab)];
  }

  private getIndicatorMwgFilter(tols: boolean, isLab: boolean): string {
    if (tols && isLab) {
      return this.TOL_ON_LAB;
    } else if (tols && !isLab) {
      return this.TOL_ON_LCH;
    } else if (!tols && isLab) {
      return this.TOL_OFF_LAB;
    } else {
      return this.TOL_OFF_LCH;
    }
  }

  public saveToStorage(identifier: string, history: VectorHistory): void {
    this.storage.setItem(identifier, JSON.stringify(history));
  }

  private getFromStorage(identifier: string): VectorHistory {
    return JSON.parse(this.storage.getItem(identifier));
  }

  private deleteFromStorage(identifier: string): void {
    this.storage.removeItem(identifier);
  }
}
