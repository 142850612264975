import { Pipe, PipeTransform } from '@angular/core';
import {RecipeEntry} from "../../models/vector-api/recipe-entry.model";

@Pipe({
  name: 'vectorPasteAmountFilter'
})
export class VectorPasteAmountFilterPipe implements PipeTransform {

  transform(recipeEntries: RecipeEntry[], hideZeroAmounts: boolean): RecipeEntry[] {
    if (!hideZeroAmounts) {
      return recipeEntries
    }
    const entriesWithoutZeroAmounts: RecipeEntry[] = [];
    for (let i = 0; i < recipeEntries.length; i++) {
      if (recipeEntries[i].Amount > 0) {
        entriesWithoutZeroAmounts.push(recipeEntries[i]);
      }
    }
    return entriesWithoutZeroAmounts;
  }

}
