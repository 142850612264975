<colimo-project-header
        *ngIf="!!project"
        [project]="project"
        [lineId]="kpiLine ? kpiLine.lineNumber : undefined"
        [modelId]="undefined"
        [kpis]="kpiLine ? kpiLine : undefined"
        class="bottom-margin-0">
</colimo-project-header>

<colimo-modal [size]="'md'"></colimo-modal>

<div class="flex-row justify-start vector-padding-left">
  <h2 class="heading">{{ 'dashboard.vectorTinting' | translate }}</h2>
</div>

<div class="vector-padding">
  <mat-card appearance="outlined" class="vector-padding-filter-card">
    <div class="flex-row config-row flex-lt-md-column">
      <!-- Color system -->
      <div class="flex-row gap-5 vector-basic-config flex-lt-md-column">
        <div class="flex-column start-start">
          <div class="bold-text">{{ 'dashboard.colorSystem' | translate }}</div>
          <mat-button-toggle-group [value]="labOn" (change)="colorCoordinatesChanged($event)" aria-label="Font Style" class="margin-top">
            <mat-button-toggle [value]="true">Lab</mat-button-toggle>
            <mat-button-toggle [value]="false">LCH</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <!-- Tolerances -->
        <div class="flex-column start-start">
          <div class="bold-text">{{ 'dashboard.tolerances' | translate }}</div>
          <mat-button-toggle-group [value]="tolerancesOn" (change)="tolerancesChanged($event)" aria-label="Font Style" class="margin-top">
            <mat-button-toggle [value]="true">{{ 'missing.on' | translate }}</mat-button-toggle>
            <mat-button-toggle [value]="false">{{ 'missing.off' | translate }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <!-- Angles -->
        <div *ngIf="mwgDataList[0] && mwgDataList[0].angles && mwgDataList[0].angles.length !== 0"
             class="flex-column">
          <div class="bold-text">{{ 'dashboard.geometry' | translate }}</div>
          <div class="flex-row margin-left--10">
            <mat-checkbox
              *ngFor="let angle of mwgDataList[0].angles; let i = index"
              color="primary"
              [checked]="selectedAngles[i]"
              (change)="angleSelectionChanged($event, i)">
              {{ angle }}°
            </mat-checkbox>
          </div>
        </div>
      </div>
      <!-- Only for QC-Mode Tinting start and Target selection with offset display -->
      <div *ngIf="batch.forecastAvailable" class="flex-row gap-20 flex-lt-md-column">
        <!-- Refresh QC measurements button -->
        <div>
          <p></p>
          <button
            class="refresh-button"
            mat-icon-button
            matTooltip="{{ 'dashboard.updateForecastMeasurements' | translate }}"
            [matTooltipPosition]="'above'"
            [disabled]="isForecastMeasurementLoading"
            (click)="updateForecastMeasurementsByBatchId()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <!-- Tinting start measurement selection - display only if we are in the QC mode -->
        <div>
          <div class="bold-text">Tinting Start</div>
          <mat-form-field appearance="fill" class="background-color-whitesmoke">
            <mat-label class="select-label small-font-size">Select Tinting START</mat-label> <!--{{'dashboard.selectRefCharge' | translate}}-->
            <mat-select class="small-font-size"
              name="tinting_target_start_{{batch.batchId}}"
              [(ngModel)]="selectedForecastMeasurement"
              [disabled]="mainCardLoading"
              (selectionChange)="onSelectedForecastMeasurementChanged($event.value)"
              id="tinting_target_start_{{batch.batchId}}">
              <mat-option
                *ngFor="let option of batch.forecastMeasurements"
                [value]="option">{{ option.info }}</mat-option>
            </mat-select> <!--style="font-size: 10px"-->
            <button mat-icon-button *ngIf="selectedForecastMeasurement" matSuffix aria-label="Clear"
                    (click)="onSelectedForecastMeasurementChanged(null); $event.stopPropagation()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- Tinting target selection - display only if we are in the QC mode -->
        <div class="start-start">
          <div class="flex-row gap-20">
            <div class="flex-column">
              <div class="bold-text">Tinting Mode</div> <!--{{ 'vector.tintingTarget' | translate }}-->
              <mat-button-toggle-group [value]="labTintingMode" (change)="labTintingModeChanged($event)" aria-label="Font Style" class="margin-top">
                <mat-button-toggle [value]="VectorHistoryService.LAB_TINTING_MODE_MASTER">Master</mat-button-toggle> <!-- {{ 'vector.master' | translate }} -->
                <mat-button-toggle [value]="VectorHistoryService.LAB_TINTING_MODE_BATCH">Batch</mat-button-toggle> <!-- {{ 'vector.batch' | translate }} -->
              </mat-button-toggle-group>
              <div class="flex-row" *ngIf="labTintingMode === VectorHistoryService.LAB_TINTING_MODE_MASTER">
                <mat-checkbox color="primary" matTooltip="Use Offset" [matTooltipPosition]="'above'"
                  [checked]="useOffset"
                  (change)="offsetUsageChanged($event)">
                </mat-checkbox>
                <div class="bold-text margin-left-right margin-top-small" [ngClass]="useOffset ? 'red-cell' : ''">Offset</div>
                <div class="margin-top-small" matTooltip="Show Offset" [matTooltipPosition]="'above'">
                  <mat-icon class="cursor-pointer" [ngClass]="useOffset ? 'red-cell' : ''" (click)="openOffsetDialog()">visibility</mat-icon>
                </div>
              </div>
            </div>
            <div *ngIf="labTintingMode === VectorHistoryService.LAB_TINTING_MODE_MASTER">
              <div class="flex-column">
                <mat-button-toggle-group [value]="labTintingOffset" (change)="labTintingOffsetChanged($event)" aria-label="Font Style" class="margin-top">
                  <div class="bold-text margin-left-right" [ngClass]="useOffset ? 'red-cell' : ''" matTooltip="OFFSET NOT IMPLEMENTED">Offset</div>
                  <mat-button-toggle [value]="VectorHistoryService.LAB_TINTING_OFFSET_BATCH">Batch</mat-button-toggle> <!-- {{ 'vector.master' | translate }} -->
                  <mat-button-toggle [value]="VectorHistoryService.LAB_TINTING_OFFSET_RS">Retain Sample</mat-button-toggle> <!-- {{ 'vector.batch' | translate }} -->
                </mat-button-toggle-group>
              </div>
              <mat-form-field *ngIf="labTintingOffset === VectorHistoryService.LAB_TINTING_OFFSET_BATCH" appearance="fill" class="background-color-whitesmoke">
                <mat-label class="select-label small-font-size">{{'dashboard.selectRefCharge' | translate}}</mat-label>
                <mat-select class="small-font-size"
                            name="tinting_offset_batch_{{batch.batchId}}"
                            [(ngModel)]="selectedOffsetBatch"
                            [disabled]="mainCardLoading"
                            (selectionChange)="selectedOffsetBatchChanged($event)"
                            id="tinting_offset_batch_{{batch.batchId}}">
                  <mat-option
                    *ngFor="let option of batch.referenceBatches"
                    [value]="option">{{option.batchNumber}}</mat-option>
                </mat-select>
                <button mat-icon-button *ngIf="selectedOffsetBatch" matSuffix aria-label="Clear"
                        (click)="selectedOffsetBatchChanged(null); $event.stopPropagation()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field *ngIf="labTintingOffset === VectorHistoryService.LAB_TINTING_OFFSET_RS" appearance="fill" class="background-color-whitesmoke">
                <mat-label class="select-label small-font-size">Select Ref Measurement</mat-label> <!--{{'dashboard.selectRefCharge' | translate}}-->
                <mat-select class="small-font-size"
                            name="tinting_offset_measurement_{{batch.batchId}}"
                            [(ngModel)]="selectedOffsetMeasurement"
                            [disabled]="mainCardLoading"
                            (selectionChange)="selectedOffsetMeasurementChanged($event)"
                            id="tinting_offset_measurement_{{batch.batchId}}">
                  <mat-option
                    *ngFor="let option of batch.forecastMeasurements"
                    [value]="option">{{ option.info }}</mat-option>
                </mat-select>
                <button mat-icon-button *ngIf="selectedOffsetMeasurement" matSuffix aria-label="Clear"
                        (click)="selectedOffsetMeasurementChanged(null); $event.stopPropagation()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div *ngIf="labTintingMode === VectorHistoryService.LAB_TINTING_MODE_BATCH">
              <div class="flex-column">
                <mat-button-toggle-group [value]="selectedTintingTarget" (change)="tintingTargetChanged($event)" aria-label="Font Style" class="margin-top">
                  <div class="bold-text margin-left-right">Target</div>
                  <mat-button-toggle [value]="VectorHistoryService.TINTING_TARGET_BATCH">Batch</mat-button-toggle> <!-- {{ 'vector.master' | translate }} -->
                  <mat-button-toggle [value]="VectorHistoryService.TINTING_TARGET_RS">Retain Sample</mat-button-toggle> <!-- {{ 'vector.batch' | translate }} -->
                </mat-button-toggle-group>
                <mat-form-field *ngIf="selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH" appearance="fill" class="background-color-whitesmoke">
                  <mat-label class="select-label small-font-size">{{'dashboard.selectRefCharge' | translate}}</mat-label>
                  <mat-select class="small-font-size"
                              name="tinting_target_batch_{{batch.batchId}}"
                              [(ngModel)]="selectedTintingTargetBatch"
                              [disabled]="mainCardLoading"
                              (selectionChange)="selectedTintingTargetBatchChanged($event)"
                              id="tinting_target_batch_{{batch.batchId}}">
                    <mat-option
                      *ngFor="let option of batch.referenceBatches"
                      [value]="option">{{option.batchNumber}}</mat-option>
                  </mat-select>
                  <button mat-icon-button *ngIf="selectedTintingTargetBatch" matSuffix aria-label="Clear"
                          (click)="selectedTintingTargetBatchChanged(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field *ngIf="selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS" appearance="fill" class="background-color-whitesmoke">
                  <mat-label class="select-label small-font-size">Select Ref Measurement</mat-label> <!--{{'dashboard.selectRefCharge' | translate}}-->
                  <mat-select class="small-font-size"
                              name="tinting_target_measurement_{{batch.batchId}}"
                              [(ngModel)]="selectedTintingTargetMeasurement"
                              [disabled]="mainCardLoading"
                              (selectionChange)="selectedTintingTargetMeasurementChanged($event)"
                              id="tinting_target_measurement_{{batch.batchId}}">
                    <mat-option
                      *ngFor="let option of batch.forecastMeasurements"
                      [value]="option">{{ option.info }}</mat-option>
                  </mat-select>
                  <button mat-icon-button *ngIf="selectedTintingTargetMeasurement" matSuffix aria-label="Clear"
                          (click)="selectedTintingTargetMeasurementChanged(null); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Vector File Input-->
      <div class="flex-row gap-5 vector-file-input">
        <div *ngIf="!cloudStorageInitializing && !cloudStorageConfig" class="flex-column">
          <label class="bold-text">{{ 'dashboard.vectorFile' | translate }}</label>
          <input type="file" accept="txt/*" (change)="vectorFileInputChanged($event)" class="small-font-size">
        </div>
        <div *ngIf="!cloudStorageInitializing && cloudStorageConfig">
          <cs-file-input [disabled]="!cloudStorageConfig" class="cloud-file-input" style=".mat-label{ font-weight: bold}"
                         [configuration]="cloudStorageConfig"
                         [label]="'dashboard.vectorFile' | translate"
                         [placeholder]="'reports.chooseFile' | translate"
                         [acceptedFileExtensions]="['txt']"
                         (valueChange)="vectorFileChanged($event)"
          ></cs-file-input>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card appearance="outlined"  class="vector-padding tinting-card-area">
    <colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="mainCardLoading"></colimo-loader>
    <div class="flex-row flex-lt-md-column">
      <div class="vector-card-colum flex-33 flex-lt-md-90">
        <div>
          <colimo-mwg-chart-list
            [showChartFilters]="false"
            [showChartTable]="false"
            [isVectorPage]="true"
            [selectedAngles]="selectedAngles"
            [batchId]="batch?.batchId"
            [activeComponents]="{ bodyOn: true, bumperOn: true }"
            [chartFilters]="{ tolerance: tolerancesOn, labSystem: labOn ? 'LAB' : 'LCH' }"
            (chartDataChange)="chartDataChanged($event)">
          </colimo-mwg-chart-list>
        </div>
      </div>
      <div class="vector-card-colum flex-33 flex-column flex-lt-md-90">
        <div class="segment-height-top">
          <colimo-chart-table *ngIf="refTableMwgData"
                              [dataSet]="refTableMwgData.data"
                              [angles]="refTableMwgData.angles"
                              [table]="refTableMwgData.table"
                              [tolerance]="refTableMwgData.tolerances"
                              [batchType]="refTableMwgData.batchType"
                              [filters]="chartFilters"
                              [selectedAngles]="selectedAngles"
                              [colorIndicator]="refGraphColorIndicator"
                              class="vector-ref-chart-table">
          </colimo-chart-table>
          <div *ngIf="!refTableMwgData">
            <p class="missingData">{{ 'dashboard.missingData' | translate }}</p>
          </div>
        </div>
        <div class="segment-height-bottom">
          <div class="flex-row">
            <label class="bold-text">Pastes:</label>
            <div matTooltip="Fix all pastes" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="setAllPastesFixed(true)">lock</mat-icon> <!-- lock   gps_fixed-->
            </div>
            <div matTooltip="All pastes non fixed" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="setAllPastesFixed(false)">lock_open</mat-icon> <!-- lock_open   gps_not_fixed-->
            </div>
            <div class="flex-4"></div>
            <div matTooltip="Activate all pastes" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="setAllPastesActivated(true)">select_all</mat-icon>
            </div>
            <div matTooltip="Deactivate all pastes" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="setAllPastesActivated(false)">deselect</mat-icon>
            </div>
            <div class="flex-4"></div>
            <div matTooltip="Show Zero Amounts" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="hidePastesWithZeroAmounts(false)">visibility</mat-icon>
            </div>
            <div matTooltip="Hide Zero Amounts" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="hidePastesWithZeroAmounts(true)">visibility_off</mat-icon>
            </div>
            <div class="flex-4"></div>
            <div matTooltip="Reset Result" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer" (click)="resetAllRecipeEntries()">undo</mat-icon>
            </div>
            <div class="flex-20"></div>
            <label class="bold-text">Tinting:</label>
            <div matTooltip="Calculate tinting Result" [matTooltipPosition]="'above'">
              <mat-icon class="cursor-pointer calculate-button" (click)="getMixedMeasurements()">calculate</mat-icon>
            </div>
          </div>
          <div class="overflow-auto">
          <mat-grid-list cols="{{ pasteColumns }}" rowHeight="{{ pasteColumnsHeightFactor }}:{{ pasteColumns }}" class="paste-grid-list">
            <mat-grid-tile-header>
              <!--<div class="flex-row"   *ngFor="let col of [].constructor(pasteColumns); let i = index">-->
              <div *ngIf="pasteColumns === 1" class="flex-row paste-grid-tile-wrapper">
                <div class="paste-grid-name">
                  Name
                </div>
                <div class="paste-grid-amount">
                  <div class="paste-grid-amount-input">
                    Amount
                  </div>
                </div>
                <div class="paste-grid-margin"></div>
                <div class="paste-grid-switch">
                  Fixed
                </div>
                <div class="paste-grid-switch">
                  Active
                </div>
              </div>
              <div *ngIf="pasteColumns === 2" class="flex-row paste-grid-tile-wrapper">
                <div class="paste-grid-name">
                  Name
                </div>
                <div class="paste-grid-amount">
                  <div class="paste-grid-amount-input">
                    Amount
                  </div>
                </div>
                <div class="paste-grid-margin"></div>
                <div class="paste-grid-switch">
                  Fixed
                </div>
                <div class="paste-grid-switch">
                  Active
                </div>
                <div class="paste-grid-name">
                  Name
                </div>
                <div class="paste-grid-amount">
                  <div class="paste-grid-amount-input">
                    Amount
                  </div>
                </div>
                <div class="paste-grid-margin"></div>
                <div class="paste-grid-switch">
                  Fixed
                </div>
                <div class="paste-grid-switch">
                  Active
                </div>
              </div>
            </mat-grid-tile-header>
            <mat-grid-tile *ngFor="let entry of recipeEntries | vectorPasteAmountFilter: hidePastesWithZeroAmount" class="paste-grid-tile">
              <div class="flex-row paste-grid-tile-wrapper">
                <div class="paste-grid-name"
                  matTooltip="{{ entry.Type + ' Min: ' + entry.Bound_Lower + ' Max: ' + entry.Bound_Upper }}"
                     [ngClass]="entry.IsActive ? 'bold-text' : ''">
                  {{ entry.Name }}
                </div>
                <div class="paste-grid-amount">
                  <input class="paste-grid-amount-input"
                    matInput type="number" [ngClass]="entry.userTouched ? 'paste-grid-amount-input-changed' : ''"
                    (ngModelChange)="pasteAmountChanged(entry, $event)"
                    [(ngModel)]="entry.Amount" step="0.01" min="0">
                </div>
                <div class="paste-grid-margin"></div>
                <div class="paste-grid-switch">
                  <mat-slide-toggle
                    [(ngModel)]="entry.IsFixed"
                    matTooltip="Set Amount Fixed" [matTooltipPosition]="'above'">
                  </mat-slide-toggle>
                </div>
                <div class="paste-grid-switch">
                  <mat-slide-toggle
                    [(ngModel)]="entry.IsActive"
                    matTooltip="Set Paste active" [matTooltipPosition]="'above'">
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-grid-tile>
            <div *ngIf="!recipeEntries || recipeEntries.length === 0">
              <p class="missingData">{{ 'notification.noData' | translate }}</p>
            </div>
          </mat-grid-list>
        </div>
        </div>
      </div>
      <div class="vector-card-colum flex-33 flex-column flex-lt-md-90">
        <div class="segment-height-top">
          <colimo-chart-table *ngIf="tableMwgData"
                              [dataSet]="tableMwgData.data"
                              [angles]="tableMwgData.angles"
                              [table]="tableMwgData.table"
                              [tolerance]="tableMwgData.tolerances"
                              [batchType]="tableMwgData.batchType"
                              [filters]="chartFilters"
                              [selectedAngles]="selectedAngles"
                              [colorIndicator]="mwgTableColorIndicator"
                              class="vector-ref-chart-table"
          ></colimo-chart-table>
          <div *ngIf="!tableMwgData">
            <p class="missingData">{{ 'dashboard.missingData' | translate }}</p>
          </div>
        </div>
        <div class="segment-height-bottom">
          <div class="flex-row">
            <label class="bold-text">History</label>
            <!--<div class="flex-90"></div>-->
            <div matTooltip="CLEAR ALL HISTORY" [matTooltipPosition]="'above'">
              <mat-icon class="red-cell cursor-pointer" (click)="clearAllHistory()">delete_forever</mat-icon>
            </div>
          </div>
          <div class="overflow-auto">
            <table mat-table matTableResponsive [dataSource]="theoryDataSource">  <!--class="mat-elevation-z8"-->
              <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef> Color </th> <!--{{ 'paste' | translate }} -->
                <td mat-cell *matCellDef="let element" (click)="historyClicked(element)">
                  <i class="material-icons" [style.color]="element.color">circle</i>
                </td>
              </ng-container>
              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> created </th> <!--{{ 'amount' | translate }}-->
                <td mat-cell *matCellDef="let element" (click)="historyClicked(element)">{{ element.created }}</td>
              </ng-container>
              <ng-container matColumnDef="ref">
                <th mat-header-cell *matHeaderCellDef> reference </th> <!--{{ 'amount' | translate }}-->
                <td mat-cell *matCellDef="let element" (click)="historyClicked(element)">{{ element.tintingTarget === VectorHistoryService.TINTING_TARGET_REF ? 'MASTER' : element.tintingTarget }}</td>
              </ng-container>
              <ng-container matColumnDef="tolerance">
                <th mat-header-cell *matHeaderCellDef> tolerance </th> <!--{{ 'amount' | translate }}-->
                <td mat-cell *matCellDef="let element" (click)="historyClicked(element)">{{ element.chartFilters?.tolerance ? 'On' : 'Off' }}</td>
              </ng-container>
              <!--<ng-container matColumnDef="info">
                <th mat-header-cell *matHeaderCellDef> Info </th> &lt;!&ndash;{{ 'active' | translate }} &ndash;&gt;
                <td mat-cell *matCellDef="let element" (click)="historyClicked(element)"> {{ element.info }} </td> &lt;!&ndash;{{ element.selectedForecastMeasurement.info }}&ndash;&gt;
              </ng-container>-->
              <ng-container matColumnDef="mdE*">
                <th mat-header-cell *matHeaderCellDef> mdE* </th>
                <td mat-cell *matCellDef="let element" (click)="historyClicked(element)"> {{ element.displayedMdE | number: '1.2-2' }} </td> <!--{{ element.selectedForecastMeasurement.info }}-->
              </ng-container>
              <ng-container matColumnDef="deleteHistory">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="red-cell cursor-pointer" (click)="deleteHistory(element)" matTooltip="DELETE"> <!--{{ 'delete' | translate }}-->
                  <mat-icon>delete_forever</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsHistory"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsHistory;" [ngClass]="row.selected ? 'selected-history' : ''"></tr> <!-- (click)="deleteHistory(row)""-->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell missingData" [attr.colspan]="displayedColumnsHistory.length">
                  {{ 'notification.noData' | translate }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

</div>
