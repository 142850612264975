<div class="flex-row">
  <p class="forecast-heading">
    {{'dashboard.predictedBehavior' | translate}}
  </p>
</div>
<div class="flex-row-lt-sm-column">
  <div class="flex-13 flex-lt-sm-100">
  <div *ngIf="calculatedCPI" class="kpi-wrapper flex-lt-sm-mrgbtn12px">
    <h4 class="kpi-name">CPI</h4>
    <span class="label is-outline {{'label-' | kpiLabel:calculatedCPI}}">Q{{calculatedCPI}}</span>
  </div>
  </div>
  <div class="form-field-group flex-50 flex-lt-sm-100">
    <mat-form-field appearance="fill">
      <mat-label class="select-label">{{'dashboard.selectRefCharge' | translate}}</mat-label>
      <mat-select
              name="forecast_{{batch.batchId}}"
              [(ngModel)]="selectedReference"
              [disabled]="isLoading"
              (selectionChange)="selectedReferenceChanged($event)"
              id="forecast_{{batch.batchId}}">
        <mat-option
                *ngFor="let option of referenceBatches"
                [value]="option">{{option.batchNumber}}</mat-option>
      </mat-select>
      <button mat-icon-button *ngIf="selectedReference" matSuffix aria-label="Clear"
              (click)="resetSelectedReference(); $event.stopPropagation()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <button
          class="btn btn-link flex-30 flex-lt-sm-100"
          (click)="onSubmit()"
          [disabled]="isLoading"
  >
    <span *ngIf="calculatedCPI">{{'missing.calcAgain' | translate}}</span>
    <span *ngIf="!calculatedCPI">{{'dashboard.calcForecast' | translate}}</span>
  </button>
    <button
            class="vector-tinting-button"
            mat-icon-button
            *ngIf="showVectorTintingButton"
            matTooltip="{{ 'dashboard.vectorTinting' | translate }}"
            [matTooltipPosition]="'above'"
            (click)="navigateToVectorTintingPage()"
    >
      <img alt="vectorTinting" src="assets/icon/vector_100x100.png"/>
    </button>
</div>
<colimo-loader [background]="'transparent'" [top]="'50%'" *ngIf="isLoading"></colimo-loader>
