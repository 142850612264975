<div *ngIf="dataAvailable" class="mwg-wrapper flex-row">
  <div [ngClass]="showChartTable === true ? 'flex-70' : 'flex-100'">
    <ng-container *ngFor="let chartType of mwgChartTypes">
      <div class="chart-wrapper" *ngIf="chartData && chartData.data && chartData?.data[chartType] && chartData.data[chartType].dataSets">
        <div class="type-label">{{ chartType | labelByChartType: chartData.tolerances }}</div>

        <colimo-mwg-chart-component
          [showChartFilters]="showChartFilters"
          [showChartTable]="showChartTable"
          [quadrant]="chartData.data[chartType].chQuadrant"
          [dataSet]="chartData.data[chartType].dataSets"
          [isVectorPage]="isVectorPage"
          [dataLabels]="lineChartLabels"
          [dataScale]="scale"
          [dataAngles]="lineChartLabels"
          [chartType]="chartType"
          [filters]="chartFilters"
          [selectedAngles]="selectedAngles"
          [activeComponents]="activeComponents"
        ></colimo-mwg-chart-component>
      </div>
    </ng-container>
  </div>
  <div class="flex-30" *ngIf="showChartTable && chartData?.data && chartData?.table" style="flex-grow: 0; flex-shrink: 0;">
    <colimo-chart-table
      [dataSet]="chartData.data"
      [angles]="chartData.angles"
      [table]="chartData.table"
      [tolerance]="chartData.tolerances"
      [batchType]="chartData.batchType"
      [filters]="chartFilters"
      [selectedAngles]="selectedAngles"
    ></colimo-chart-table>
  </div>
</div>

<div *ngIf="!dataAvailable && !isLoading">
  <p class="missingData">{{ 'dashboard.missingData' | translate }}</p>
</div>

<colimo-loader [background]="'transparent'" [top]="'55%'" *ngIf="isLoading"></colimo-loader>
