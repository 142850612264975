import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Router} from '@angular/router';
import {Iproject} from '../../../models/project.model';
import {ProjectService} from '../../../services/project/project.service';
import {IBatchForecastMeasurement} from '../../../models/batch-forecast-measurement.model';
import {IBatch} from '../../../models/batch.model';
import {environment} from '../../../../environments/environment';
import {ImportFile, RuntimeConfiguration} from '@colorcare/ngx-cloud-storage';
import {IUserSummary, IUserSummaryActor, ServiceType, UserManagementClient} from '@colorcare/user-management-client';
import {take, takeUntil} from 'rxjs/operators';
import {VectorService} from '../../../services/vector/vector.service';
import {ChartDataService} from "../../../services/chart-data/chart-data.service";
import {IMwgData} from "../../../models/chart-mwg.model";
import {MixedMeasurementResponse} from "../../../models/vector-api/mixed-measurement-response.model";
import {IFdgData} from "../../../models/chart-fdg.model";
import {IFogData} from "../../../models/chart-fog.model";
import {ILpgData} from "../../../models/chart-lpg.model";
import {MatTableDataSource} from "@angular/material/table";
import {ColorDataService} from "../../../services/color-data/color-data.service";
import {VectorHistory} from "../../../models/vector-api/vector-history.model";
import {LocalStorageService} from "../../../services/local-storage/local-storage.service";
import {Recipe} from "../../../models/vector-api/recipe.model";
import {RecipeEntry} from "../../../models/vector-api/recipe-entry.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChartFilters} from "../../../models/chart-filters.model";
import {IKpiLine} from "../../../models/kpi.model";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {VectorHistoryService} from "../../../services/vector/vector-history.service";
import {VectorTheory} from "../../../models/vector-api/vector-theory.model";
import {forkJoin, Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {OffsetDto} from "../../../models/vector-api/offset-dto.model";
import {MatDialog} from "@angular/material/dialog";
import {OffsetDialogComponent} from "../offset-dialog/offset-dialog.component";
import {OffsetHistoryService} from "../../../services/vector/offset-history.service";

@Component({
  selector: 'colimo-vector-tinting-page',
  templateUrl: './vector-tinting-page.component.html',
  styleUrls: ['./vector-tinting-page.component.scss'],
  providers: [ProjectService, LocalStorageService]
  })
export class VectorTintingPageComponent extends BaseComponent implements OnInit {

  public project: Iproject;
  public batch: IBatch;
  public kpiLine: IKpiLine;
  public mwgDataList: IMwgData[] = [];

  public labOn: boolean;
  public tolerancesOn: boolean;
  public selectedAngles: boolean[] = [true, true, true, true, true, true];
  /*public mwgTableDataAngles: any[] = [{"-15": true}, {"15": true}, {"25": true}, {"45": true}, {"75": true}, {"110": true}];
  public mwgTableDataAnglesSelectedAngles: boolean[] = [true, true, true, true, true, true];*/
  public chartFilters: ChartFilters;
  public refGraphColorIndicator: string;
  public mwgTableColorIndicator: string;

  public selectedForecastMeasurement: IBatchForecastMeasurement;
  public isForecastMeasurementLoading = false;
  public selectedTintingTargetMeasurement: IBatchForecastMeasurement;
  public selectedTintingTarget: string;
  public labTintingMode: string;
  public labTintingOffset: string;
  public selectedTintingTargetBatch: any;
  public selectedOffsetBatch: any;
  public selectedOffsetMeasurement: IBatchForecastMeasurement;
  public offsetDto: OffsetDto;
  public useOffset: boolean;

  public cloudStorageConfig: RuntimeConfiguration;
  public cloudStorageInitializing: boolean = false;

  public displayedColumns: string[] = ['paste', 'amount', 'fixed', 'active'];
  public displayedColumnsHistory: string[] = ['color', 'created', 'ref', 'tolerance', 'mdE*', 'deleteHistory'];
  public recipeEntries: RecipeEntry[] = [];
  public hidePastesWithZeroAmount: boolean = false;
  public history: VectorHistory;
  public theoryDataSource;
  public tableMwgData: IMwgData;
  public refTableMwgData: IMwgData;
  public mainCardLoading: boolean = false;
  public pasteColumns: number = 1;
  public pasteColumnsHeightFactor: number = 20;
  private numberOfRecipeEntriesMaxSingleColumn = 10;

  private vectorFile: File;
  // default values for lower and upper bounds for recipe entries
  private LOWER_BOUND_DEFAULT: number = 0.0;
  private UPPER_BOUND_DEFAULT: number = 3.0;

  constructor(private router: Router,
              private vectorService: VectorService,
              private vectorHistoryService: VectorHistoryService,
              private chartDataService: ChartDataService,
              private colorDataService: ColorDataService,
              private projectService: ProjectService,
              private translate: TranslateService,
              private snackBar: MatSnackBar,
              private offsetHistoryService: OffsetHistoryService,
              public offsetDialog: MatDialog,
              private cd: ChangeDetectorRef) {
    super();
    this.project = this.router.getCurrentNavigation().extras.state.project;
    this.batch = this.router.getCurrentNavigation().extras.state.batch;
    this.kpiLine = this.project.kpiLine;
    if (this.kpiLine) {
      // exclude corresponding measurements here
      this.chartFilters = {
        batchId: this.batch.batchId,
        lineId: this.kpiLine.lineNumber,
        bumper: this.batch.productType === 'BUMPER',
        body: this.batch.productType === 'BODY'
      };
    } else {
      this.chartFilters = { batchId: this.batch.batchId };
    }
  }

  public ngOnInit(): void {
    this.initCloudStorageConfig();
    // default filters
    this.labOn = true;
    this.chartFilters.labSystem = 'LAB';
    this.selectedTintingTarget = VectorHistoryService.TINTING_TARGET_REF;
    this.labTintingMode = VectorHistoryService.LAB_TINTING_MODE_MASTER;
    this.labTintingOffset = VectorHistoryService.LAB_TINTING_OFFSET_BATCH;
    this.refGraphColorIndicator = this.colorDataService.getSimpleMultiBatchColors()[0];
    this.initEmptyState();
    this.loadHistory();
  }

  private loadMwgRefFromHistory(): boolean {
    const mwgRefHistory = this.vectorHistoryService.getMwgRef(this.getHistoryIdentifier(), this.chartFilters, this.selectedTintingTarget);
    if (mwgRefHistory) {
      this.mwgDataList.push(mwgRefHistory);
      this.refTableMwgData = mwgRefHistory;
      this.updateChartData(this.mwgDataList);
      this.tolerancesOn = mwgRefHistory.tolerances;
      this.mainCardLoading = false;
      return true;
    }
    return false;
  }

  private updateAllMwgDatas(): void {
    // this methods gets called when tolerances or color system changed
    // check what mwg data (ref and theories) is already present for the current filters and load all missing data from server
    this.mwgDataList = [];
    this.mainCardLoading = true;
    let mwgRefIncluded: boolean = false;

    const observables: Observable<IMwgData>[] = [];

    // load mwg ref data from history - if not present add call to server for mwg ref according to current mode
    if (!this.loadMwgRefFromHistory()) {
      if (this.project.kpiLine) {
        observables.push(this.chartDataService.getMwgDataOemLine(this.chartFilters));
      } else if (this.selectedForecastMeasurement && ((this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH && this.selectedTintingTargetBatch) ||
        (this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS && this.selectedTintingTargetMeasurement))) {
        if (this.selectedForecastMeasurement && this.batch && (this.selectedTintingTargetBatch || this.selectedTintingTargetMeasurement)) {
          observables.push(this.chartDataService.getMwgDataForQCForReference(this.chartFilters, this.selectedForecastMeasurement.id,
            ((this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH && this.selectedTintingTargetBatch) ? this.selectedTintingTargetBatch.id : undefined),
            ((this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS && this.selectedTintingTargetMeasurement) ? this.selectedTintingTargetMeasurement.id : undefined)));
        }
      } else if (this.selectedForecastMeasurement && this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_REF) {
        if (this.selectedForecastMeasurement && this.batch) {
          observables.push(this.chartDataService.getMwgDataForQC(this.chartFilters, this.selectedForecastMeasurement.id));
        }
      }
      // will the response contain new reference mwg data
      mwgRefIncluded = (observables.length > 0);
    }

    // check for current Theories if mwg data for current filters is present - if not add call to server for theories according to current mode
    let currentHistory: VectorHistory = this.vectorHistoryService.getVectorHistory(this.getHistoryIdentifier());
    // this array holds the information for each theory if it was updated or not and gets queried for all theory responses
    const theoryUpdated: boolean[] = [];
    if (currentHistory && currentHistory.vectorTheories && currentHistory.vectorTheories.length > 0) {
      currentHistory.vectorTheories.forEach((theory) => {
        const mwgTheoryForHistory = this.vectorHistoryService.getMwgTheoryForFilters(theory, this.chartFilters, this.selectedTintingTarget);
        if (!mwgTheoryForHistory && theory.mixResponse && theory.mixResponse.Theory) {
          observables.push(this.chartDataService.getMwgDataForTheory(this.chartFilters, theory.mixResponse.Theory,
            (this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH && this.selectedTintingTargetBatch) ? this.selectedTintingTargetBatch.id : undefined,
            (this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS && this.selectedTintingTargetMeasurement) ? this.selectedTintingTargetMeasurement.id : undefined));
          theoryUpdated.push(true);
        } else {
          theoryUpdated.push(false);
        }
      });
    }

    if (observables.length > 0) {
      // load missing mwg data from server
      forkJoin(observables).pipe(takeUntil(this.stop$)).subscribe({
        next: (mwgDatas: IMwgData[]) => {
          if (mwgRefIncluded) {
            // if ref was reloaded add ref as first mwgData
            if (this.selectedForecastMeasurement) {
              mwgDatas[0].table.name = this.selectedForecastMeasurement.info;
            }
            const mwgRef = this.vectorHistoryService.addMwgRef(this.getHistoryIdentifier(), this.chartFilters, mwgDatas[0], this.project, this.batch, this.selectedForecastMeasurement, this.kpiLine);
            this.mwgDataList.push(mwgRef);
            this.refTableMwgData = mwgRef;
          }
          // TODO is this always the case here if theories were reloaded
          let indexOffset = mwgRefIncluded ? 1 : 0;
          let selectedTheoryIndex = -1;
          // history might have been updated with new mwgRef Data so reload it here
          currentHistory = this.vectorHistoryService.getVectorHistory(this.getHistoryIdentifier());
          if (currentHistory && currentHistory.vectorTheories && currentHistory.vectorTheories.length > 0) {
            currentHistory.vectorTheories.forEach((theory, index) => {
              //const mwgIndex = (index + indexOffset);
              if (theoryUpdated[index] && mwgDatas[indexOffset]) {
                theory = this.vectorHistoryService.updateTheoryMwg(theory, this.chartFilters, mwgDatas[indexOffset], this.batch, this.selectedTintingTarget);
                indexOffset++;
              }
              // check for selected
              if (theory.selected) {
                selectedTheoryIndex = index;
              }
            });
            // add updated theories to mwg chart data
            const mwgTheories: IMwgData[] = this.vectorHistoryService.getMwgTheoriesForHistory(currentHistory, this.chartFilters, this.selectedTintingTarget);
            if (mwgTheories && mwgTheories.length > 0) {
              this.mwgDataList.push(...mwgTheories);
            }
          }

          // update theory table data
          if (selectedTheoryIndex >= 0) {
            const theoryTableMwgData = this.vectorHistoryService.getMwgTheoryForFilters(currentHistory.vectorTheories[selectedTheoryIndex], this.chartFilters, this.selectedTintingTarget);
            if (theoryTableMwgData) {
              this.tableMwgData = theoryTableMwgData;
            }
          }

          // display updated data
          this.updateChartData(this.mwgDataList);
          this.vectorHistoryService.saveToStorage(this.getHistoryIdentifier(), currentHistory);
          this.history = currentHistory;
          this.theoryDataSource.data = this.history.vectorTheories;
          this.mainCardLoading = false;
        }, error: (error) => {
          // handle error
          this.showError(error);
          this.mainCardLoading = false;
        }
      });
    } else {
      // all data present in history just display it - only theories need to be added here to the graph data because ref is already present through history check on top of this function
      if (currentHistory && currentHistory.vectorTheories && currentHistory.vectorTheories.length > 0) {
        currentHistory.vectorTheories.forEach((theory) => {
          const mwgTheoryForHistory = this.vectorHistoryService.getMwgTheoryForFilters(theory, this.chartFilters, this.selectedTintingTarget);
          if (mwgTheoryForHistory) {
            this.mwgDataList.push(mwgTheoryForHistory);
          }
          // display selected theory table data
          const selectedTheoryIndex = this.getSelectedTheoryIndex();
          if (selectedTheoryIndex >= 0) {
            const theoryTableMwgData = this.vectorHistoryService.getMwgTheoryForFilters(currentHistory.vectorTheories[selectedTheoryIndex], this.chartFilters, this.selectedTintingTarget);
            if (theoryTableMwgData) {
              this.tableMwgData = theoryTableMwgData;
            }
          }
        });
      }

      // display data from history with updated mwgRef and theories according to the current selected Filters
      this.updateChartData(this.mwgDataList);
      this.mainCardLoading = false;
      if (currentHistory) {
        this.vectorHistoryService.saveToStorage(this.getHistoryIdentifier(), currentHistory);
        this.history = currentHistory;
        this.theoryDataSource.data = this.history.vectorTheories;
      }
    }
  }

  public onSelectedForecastMeasurementChanged(selectedMeasurement: IBatchForecastMeasurement) {
    this.selectedForecastMeasurement = selectedMeasurement;
    if (this.selectedForecastMeasurement) {
      this.loadHistory();
    }
  }

  public historyClicked(row): void {
    this.history.vectorTheories.forEach((t) => {
      t.selected = false;
    });
    row.selected = true;
    this.recipeEntries = row.recipeEntries;
    this.evaluateRecipeColumns();
    this.tableMwgData = this.vectorHistoryService.getMwgTheoryForFilters(row, this.chartFilters, this.selectedTintingTarget);
    this.mwgTableColorIndicator = row.color;
    this.vectorHistoryService.saveToStorage(this.getHistoryIdentifier(), this.history);
    //this.vectorFile = row.vectorFile;
  }

  private getSelectedTheoryIndex(): number {
    let index = -1;
    if (!this.history || !this.history.vectorTheories || this.history.vectorTheories.length < 1) {
      return index;
    }
    this.history.vectorTheories.forEach((t) => {
      if (t.selected) {
        index = this.history.vectorTheories.indexOf(t);
      }
    });
    return index;
  }

  public uploadVectorFile(): void {
    if (this.vectorFile && this.selectedForecastMeasurement && this.batch) {
      this.mainCardLoading = true;
      // check for user defined paste values
      const recipe: Recipe = this.vectorService.getCurrentRecipe(this.recipeEntries);

      this.vectorService.uploadVectorFile(this.vectorFile, this.selectedForecastMeasurement.id, this.batch.batchId,
        ((this.selectedTintingTargetBatch && this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH) ? this.selectedTintingTargetBatch.id : undefined),
        ((this.selectedTintingTargetMeasurement && this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS) ? this.selectedTintingTargetMeasurement.id : undefined),
        this.chartFilters, recipe,
        ((this.useOffset && this.offsetDto) ? this.offsetDto : undefined)).subscribe({
        next: (response: MixedMeasurementResponse) => {
          this.handleMixedMeasurementsResponse(response);
          this.mainCardLoading = false;
        }, error: (error) => {
          // error handling
          this.showError(error);
          this.mainCardLoading = false;
        }})
    }
  }

  public uploadVectorFileOemLine(): void {
    if (this.vectorFile && this.project.kpiLine.lineNumber && this.batch.batchId) {
      this.mainCardLoading = true;
      // check for user defined paste values
      const recipe: Recipe = this.vectorService.getCurrentRecipe(this.recipeEntries);

      this.vectorService.uploadVectorFileOemLine(this.vectorFile, this.project.kpiLine.lineNumber, this.batch.batchId, this.chartFilters, recipe).subscribe({
        next: (response: MixedMeasurementResponse) => {
          this.handleMixedMeasurementsResponse(response);
          this.mainCardLoading = false;
        }, error: (error) => {
          // error handling
          this.showError(error);
          this.mainCardLoading = false;
        }})
    }
  }

  private handleMixedMeasurementsResponse(response: MixedMeasurementResponse): void {
    if (!response) {
      this.showMsg('An error occurred -> No response');
      this.mainCardLoading = false;
      return;
    }

    if (response.Status !== 'OK') {
      this.showMsg('An error occurred -> ' + (response.Status ? response.Status : 'No response'));
      this.mainCardLoading = false;
      return;
    }

    this.recipeEntries = this.vectorService.setAmountForRecipeEntries(response);
    this.evaluateRecipeColumns();

    response.AvgView.startDate = response.Created.toString();
    const colorForHistory = this.vectorHistoryService.getColorForHistory(this.history.vectorTheories.length + 1, this.history);
    response.AvgView = this.vectorService.setCustomLabelForMwg(response.AvgView, 'theory-' + response.Created, colorForHistory);
    const mDE = this.vectorService.round(this.vectorService.calculateMdE(response.AvgView, this.batch), 2);
    if (mDE >= 0) {
      response.AvgView.table.name += ' - mdE* ' + mDE;
    }

    this.mwgDataList.push(response.AvgView);
    this.tableMwgData = response.AvgView;
    this.mwgTableColorIndicator = colorForHistory;
    this.updateChartData(this.mwgDataList);

    this.addTheoryToHistory(response, colorForHistory, mDE);
  }

  private addTheoryToHistory(response: MixedMeasurementResponse, colorForHistory: string, mdE: number): void {
    let currentHistory = this.vectorHistoryService.getVectorHistory(this.getHistoryIdentifier());
    if (currentHistory && currentHistory.vectorTheories && currentHistory.vectorTheories.length > 0) {
      currentHistory.vectorTheories.forEach((t) => {
        t.selected = false;
      });
    }
    currentHistory = this.vectorHistoryService.addTheoryToHistory(currentHistory, this.chartFilters, response, this.recipeEntries, colorForHistory, mdE, this.selectedTintingTarget);
    this.history = currentHistory;
    this.theoryDataSource.data = this.history.vectorTheories;
  }

  public clearAllHistory(): void {
    this.vectorHistoryService.deleteAllVectorHistory();
    this.loadHistory();
  }

  public deleteHistory(row): void {
    this.history = this.vectorHistoryService.deleteSingleVectorHistory(this.history, row);
    this.theoryDataSource.data = this.history.vectorTheories;
    this.loadHistory();
  }

  private getHistoryIdentifier(): string {
    if (this.selectedForecastMeasurement && this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_REF) {
      return VectorHistoryService.STORAGE_PREFIX + 'P_' + this.project.id + '-B_' + this.batch.batchId + '-MQC_' + this.selectedForecastMeasurement.id + '-T_' +VectorHistoryService.TINTING_TARGET_REF;
    } else if (this.selectedForecastMeasurement && this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH && this.selectedTintingTargetBatch) {
      return VectorHistoryService.STORAGE_PREFIX + 'P_' + this.project.id + '-B_' + this.batch.batchId + '-MQC_' + this.selectedForecastMeasurement.id + '-T_B_' + this.selectedTintingTargetBatch.id;
    } else if (this.selectedForecastMeasurement && this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS && this.selectedTintingTargetMeasurement) {
      return VectorHistoryService.STORAGE_PREFIX + 'P_' + this.project.id + '-B_' + this.batch.batchId + '-MQC_' + this.selectedForecastMeasurement.id + '-T_M_' + this.selectedTintingTargetMeasurement.id;
    } else if (this.kpiLine) {
      return VectorHistoryService.STORAGE_PREFIX + 'P_' + this.project.id + '-B_' + this.batch.batchId + '-L_' + this.kpiLine.lineNumber + '-T_' + VectorHistoryService.TINTING_TARGET_REF;
    }

    return null;
  }

  private loadHistory(): void {
    // check for local history in storage
    const localHistory: VectorHistory = this.vectorHistoryService.getVectorHistory(this.getHistoryIdentifier());
    if (!localHistory) {
      // no history so reset data and load the current ref data if possible
      this.initEmptyState();
      this.updateAllMwgDatas();
      return;
    }

    // display history from storage
    // ref data
    const mwgRefHistory = this.vectorHistoryService.getMwgRef(this.getHistoryIdentifier(), this.chartFilters, this.selectedTintingTarget);
    if (mwgRefHistory) {
      this.mwgDataList = [];
      this.mwgDataList.push(mwgRefHistory);
      this.refTableMwgData = mwgRefHistory;
      this.tolerancesOn = mwgRefHistory.tolerances;
      this.chartFilters.tolerance = this.tolerancesOn;
    }

    // theory data if present
    if (localHistory.vectorTheories && localHistory.vectorTheories.length > 0) {
      const firstVectorTheory: VectorTheory = localHistory.vectorTheories[0];
      this.recipeEntries = firstVectorTheory.recipeEntries;
      this.evaluateRecipeColumns();
      this.tableMwgData = this.vectorHistoryService.getMwgTheoryForFilters(firstVectorTheory, this.chartFilters, this.selectedTintingTarget);
      this.mwgTableColorIndicator = firstVectorTheory.color;

      // select first theory
      localHistory.vectorTheories.forEach((t) => {
        t.selected = false;
      });
      firstVectorTheory.selected = true;

      // get mwg theories for selected tinting target
      const mwgTheories: IMwgData[] = this.vectorHistoryService.getMwgTheoriesForHistory(localHistory, this.chartFilters, this.selectedTintingTarget);
      if (mwgTheories && mwgTheories.length > 0) {
        this.mwgDataList.push(...mwgTheories);
      }
    } else {
      this.tableMwgData = null;
      this.mwgTableColorIndicator = null;
      this.recipeEntries = null;
    }

    this.history = localHistory;
    this.theoryDataSource.data = this.history.vectorTheories;
    this.vectorHistoryService.saveToStorage(this.getHistoryIdentifier(), this.history);
    this.updateChartData(this.mwgDataList);
  }

  public resetAllRecipeEntries(): void {
    if (this.recipeEntries && this.recipeEntries.length > 0) {
      this.recipeEntries.forEach((entry) => {
        entry.Amount = entry.originalAmount;
        entry.userTouched = false;
        entry.IsActive = entry.originalActive;
        entry.IsFixed = entry.originalFixed;
        if (entry.IsFixed) {
          entry.Bound_Lower = entry.Amount;
          entry.Bound_Upper = entry.Amount;
        } else {
          entry.Bound_Lower = this.LOWER_BOUND_DEFAULT;
          entry.Bound_Upper = this.UPPER_BOUND_DEFAULT;
        }
      });
    }
  }

  public hidePastesWithZeroAmounts(hide: boolean): void {
    this.hidePastesWithZeroAmount = hide;
    // TODO set paste active according to display?
  }

  public chartDataChanged(chartData: IMwgData | IFdgData | IFogData | ILpgData): void {
    setTimeout(() => {
      this.tolerancesOn = chartData.tolerances;
    })
  }

  public getMixedMeasurements(): void {
    if (!this.vectorFile) {
      this.showMsg('No Vector File selected');
      return;
    }

    if (this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_BATCH && !this.selectedTintingTargetBatch) {
      this.showMsg('No Ref Batch selected');
      return;
    }

    if (this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_RS && !this.selectedTintingTargetMeasurement) {
      this.showMsg('No Retain Sample selected');
      return;
    }

    if (this.project.kpiLine) {
      this.uploadVectorFileOemLine();
    } else if (this.selectedForecastMeasurement) {
      this.uploadVectorFile();
    }
  }

  public colorCoordinatesChanged(event: MatButtonToggleChange): void {
    this.labOn = event.value;
    this.chartFilters.tolerance = this.tolerancesOn;
    this.chartFilters.labSystem = this.labOn ? 'LAB' : 'LCH';
    this.updateAllMwgDatas();
  }

  public tolerancesChanged(event: MatButtonToggleChange): void {
    this.tolerancesOn = event.value;
    this.chartFilters.tolerance = this.tolerancesOn;
    this.chartFilters.labSystem = this.labOn ? 'LAB' : 'LCH';
    this.updateAllMwgDatas();
  }

  public angleSelectionChanged(event: MatCheckboxChange, index: number): void {
    this.selectedAngles[index] = event.checked;
    this.updateSelectedAngles(this.selectedAngles)
  }

  public updateSelectedAngles(updatedAngles: boolean[]): void {
    this.selectedAngles = [...updatedAngles];
    this.cd.detectChanges();
  }

  public updateForecastMeasurementsByBatchId(): void {
    this.isForecastMeasurementLoading = true;
    this.projectService.updateForecastByBatchId({ batchId: this.batch.batchId }).subscribe({
      next: (numberOfNewMeasurements: number) => {
        if (numberOfNewMeasurements > 0) {
          this.reloadForecastMeasurements();
        }
        let message = this.translate.instant('dashboard.updateForecastMeasurements');
        if (numberOfNewMeasurements >= 0) {
          message += ' - ' + numberOfNewMeasurements + ' ' + this.translate.instant('dashboard.updatedForecastMeasurements');
        } else {
          message += ' - ' + this.translate.instant('dashboard.errorUpdatingForecastMeasurements');
        }
        this.showMsg(message);
        this.isForecastMeasurementLoading = false;
      },
      error: (error) => {
        this.isForecastMeasurementLoading = false;
        this.showError(error);
      },
      complete: () => {
        this.isForecastMeasurementLoading = false;
      },
    });
  }

  public tintingTargetChanged(event: MatButtonToggleChange): void {
    this.selectedTintingTarget = event.value;
    this.loadHistory();
  }

  public labTintingModeChanged(event: MatButtonToggleChange): void {
    this.labTintingMode = event.value;
    if (this.labTintingMode === VectorHistoryService.LAB_TINTING_MODE_MASTER) {
      if (!(this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_REF)) {
        this.tintingTargetChanged({ value: VectorHistoryService.TINTING_TARGET_REF } as MatButtonToggleChange);
      }
    } else if (this.selectedTintingTarget === VectorHistoryService.TINTING_TARGET_REF) {
      this.tintingTargetChanged({ value: VectorHistoryService.TINTING_TARGET_BATCH } as MatButtonToggleChange);
    }
  }

  public labTintingOffsetChanged(event: MatButtonToggleChange): void {
    this.labTintingOffset = event.value;
  }

  public selectedOffsetBatchChanged(event: any): void {
    if (!event || !event.value) {
      this.selectedOffsetBatch = null;
      this.offsetDto = null;
      this.useOffset = false;
    } else {
      this.selectedOffsetBatch = event.value;
      this.evaluateOffset();
    }
  }

  public selectedOffsetMeasurementChanged(event: any): void {
    if (!event || !event.value) {
      this.selectedOffsetMeasurement = null;
      if (this.selectedOffsetBatch) {
        this.evaluateOffset();
      }
    } else {
      this.selectedOffsetMeasurement = event.value;
      if (this.selectedOffsetBatch) {
        this.evaluateOffset();
      }
    }
  }

  public openOffsetDialog(): void {
    if (this.offsetDto) {
      this.offsetDialog.open(OffsetDialogComponent, {
        data: {
          offsetDialogTitle: 'Offset: ' + (this.selectedOffsetMeasurement ? this.selectedOffsetMeasurement.info + ' - ' : '') + this.selectedOffsetBatch.batchNumber,
          mwgData: this.offsetDto.AvgView,
        },
      });
    } else {
      this.showMsg('No Offset calculated');
    }
  }

  public offsetUsageChanged(event: MatCheckboxChange): void {
    this.useOffset = event.checked;
  }

  private evaluateOffset(): void {
    if (this.selectedOffsetBatch) {
      const offsetFromHistory = this.offsetHistoryService.getFromHistory(this.selectedOffsetBatch, this.selectedOffsetMeasurement);
      if (offsetFromHistory) {
        this.offsetDto = offsetFromHistory;
        this.useOffset = true;
        return;
      }
      this.mainCardLoading = true;
      this.vectorService.getOffset(this.selectedOffsetBatch.id, (this.selectedOffsetMeasurement ? this.selectedOffsetMeasurement.id : undefined)).subscribe({
        next: (response: OffsetDto) => {
          this.offsetDto = response;
          this.useOffset = true;
          this.offsetHistoryService.addToHistory(this.selectedOffsetBatch, this.selectedOffsetMeasurement, response);
          this.mainCardLoading = false;
        }, error: (error) => {
          // error handling
          this.showError(error);
          this.offsetDto = null;
          this.useOffset = false;
          this.mainCardLoading = false;
        }, complete: () => {
          this.mainCardLoading =false;
        }})
    } else {
      this.offsetDto = null;
    }
  }

  public selectedTintingTargetBatchChanged(event: any): void {
    if (!event || !event.value) {
      this.selectedTintingTargetBatch = null;
    } else {
      this.selectedTintingTargetBatch = event.value;
    }
    if (this.selectedTintingTargetBatch) {
      this.loadHistory();
    }
  }

  public selectedTintingTargetMeasurementChanged(event: any): void {
    if (!event || !event.value) {
      this.selectedTintingTargetMeasurement = null;
    } else {
      this.selectedTintingTargetMeasurement = event.value;
    }
    if (this.selectedTintingTargetMeasurement) {
      this.loadHistory();
    }
  }

  public pasteAmountChanged(paste: RecipeEntry, newAmount: number): void {
    const entry = this.recipeEntries.find((p) => p.Name === paste.Name);
    if (entry.originalAmount !== newAmount) {
      paste.userTouched = true;
    } else {
      paste.userTouched = false;
    }
  }

  public setAllPastesActivated(active: boolean): void {
    if (!this.recipeEntries || this.recipeEntries.length < 1) {
      return;
    }
    this.recipeEntries.forEach((entry) => {
      entry.IsActive = active;
    });
  }

  public setAllPastesFixed(fixed: boolean): void {
    if (!this.recipeEntries || this.recipeEntries.length < 1) {
      return;
    }
    this.recipeEntries.forEach((entry) => {
      entry.IsFixed = fixed;
    });
  }

  public forecastInit(selectedForecastMeasurementId: number): void {
    setTimeout(() => {
      this.selectedForecastMeasurement = this.batch.forecastMeasurements.find((item: IBatchForecastMeasurement) => {
        return item.id === selectedForecastMeasurementId;
      });
    });
  }

  public vectorFileChanged(importFiles: ImportFile[]): void {
    if (importFiles && importFiles.length > 0) {
      this.vectorFile = importFiles[0];
    } else {
      this.vectorFile = null;
    }
  }

  public vectorFileInputChanged(event: any): void {
    if (event.target.files && event.target.files.length > 0) {
      this.vectorFile = event.target.files[0];
    } else {
      this.vectorFile = null;
    }
  }

  private updateChartData(data: IMwgData[]): void {
    this.vectorService.updateMwgData(data);
  }

  private initEmptyState(): void {
    this.mwgDataList = [];
    this.updateChartData(this.mwgDataList);
    this.history = { vectorTheories: [] } as VectorHistory;
    this.theoryDataSource = new MatTableDataSource(this.history.vectorTheories);
    this.recipeEntries = [];
    this.refTableMwgData = null;
    this.tableMwgData = null;
    this.mwgTableColorIndicator = null;
  }

  private showMsg(msg: string, duration?: number): void {
    this.snackBar.open(msg, 'Close', { duration: duration ? duration : 3500 });
  }

  private showError(error: any, duration?: number): void {
    const dura = duration ? duration : 5000;
    if (error && error.error && error.error.apierror && error.error.apierror.message) {
      this.snackBar.open(error.error.apierror.message, 'Close', { duration: dura, politeness: 'assertive'});
    } else {
      this.snackBar.open('An error occurred', 'Close', { duration: dura, politeness: 'assertive'});
    }
  }

  private evaluateRecipeColumns(): void {
    if (this.recipeEntries && this.recipeEntries.length > this.numberOfRecipeEntriesMaxSingleColumn) {
      this.pasteColumns = 2;
    } else {
      this.pasteColumns = 1;
    }
  }

  private reloadForecastMeasurements(): void {
    this.isForecastMeasurementLoading = true;
    this.projectService.getForecastMeasurementsByBatchId({ batchId: this.batch.batchId }).subscribe({
      next: (forecastMeasurements: IBatchForecastMeasurement[]) => {
        if (forecastMeasurements) {
          /*// reset current selection after update
          this.selectedMeasurementChanged(null)*/
          this.batch.forecastMeasurements = forecastMeasurements;
        }
      },
      error: () => {
        this.isForecastMeasurementLoading = false;
      },
      complete: () => {
        this.isForecastMeasurementLoading = false;
      },
    });
  }

  private initCloudStorageConfig(): void {

    const jwt = localStorage.getItem('JWT');
    if (!jwt) {
      return;
    }
    const mayBeEvolutionToken: string = jwt.substring(jwt.indexOf(' ') + 1);
    if (!mayBeEvolutionToken) {
      return;
    }
    const userManagementClient: UserManagementClient = new UserManagementClient({
      baseUrl: environment.colorCareEvoUserUrl,
      token: mayBeEvolutionToken,
    });

    this.cloudStorageInitializing = true;

    userManagementClient.getCurrentUserSummary().pipe(take(1)).subscribe({next: (userSummary: IUserSummary) => {
      // check if the user has an evolution actor
      if (userSummary && userSummary.actors && userSummary.actors.length > 0) {
        const udsActor: IUserSummaryActor = userSummary.actors.find(actor => actor.client.service.type === ServiceType.USER_DATA);
        if (udsActor) {
          this.cloudStorageConfig = {
            token: mayBeEvolutionToken,
            actorId: udsActor.id,
            clientId: udsActor.client.id
          };
        }
        this.cloudStorageInitializing = false;
      }
    }, error: () => {
      // do nothing in case of error
      this.cloudStorageConfig = null;
      this.cloudStorageInitializing = false;
    }, complete: () => {
      this.cloudStorageInitializing = false;
    }});
  }

  protected readonly VectorHistoryService = VectorHistoryService;
}
