import { Pipe, PipeTransform } from '@angular/core';
import { ComponentStatus } from '../../models/component-status.model';

/**
 * Determines the css class of given component status.
 */
@Pipe({
  name: 'componentStatusCssClass',
})
export class ComponentStatusCssClassPipe implements PipeTransform {
  public transform(componentStatus: ComponentStatus, tolerancesOn: boolean, defaultShowDe?: boolean): string {
    if (componentStatus == null || tolerancesOn == null) {
      return '';
    }
    if (defaultShowDe === true && tolerancesOn === false) {
      return 'gray';
    }
    if(componentStatus.measurementColor === 'yellow') {
      return 'orange';
    }
    return componentStatus.measurementColor;
  }
}
