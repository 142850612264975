<div class="flex-row">
  <p class="line-number">{{ 'dashboard.result' | translate }} {{ kpiLine | lineName }}</p>
  <mat-icon *ngIf="hasTinterAdditions"
            class="tinter-addition-circle"
            [matTooltip]="'dashboard.withTinterAdditions' | translate"
            matTooltipPosition="above"
            matTooltipClass="tinter-addition-tooltip">circle</mat-icon>
</div>
<div class="flex-row">
  <colimo-mapped-measurements [measurementCount]="kpiLine.measurementCount"></colimo-mapped-measurements>
</div>
<div class="flex-row">
  <div class="flex-60 flex-lt-md-90 flex-lt-sm-100 batch-kpi-line">
    <colimo-kpi [kpis]="kpiLine.kpis" [kpiLine]="kpiLine" [batchId]="batchId" [batchState]="batchState"></colimo-kpi>
  </div>
  <div class="flex-10 flex-lt-sm-0"></div>
  <div *ngIf="!isMobile" class="flex-30 flex-lt-md-10 flex-lt-sm-0 justify-center flex-row">
    <div class="btn btn-link is-tablet-hidden" (click)="openBatch()">
      {{ 'dashboard.details' | translate }}
      <colimo-icons [icon]="'arrow-small'" [width]="'8px'" [height]="'11px'" [top]="'1px'"></colimo-icons>
    </div>
      <div [hidden]="(kpiLine.lineNumber === '*')" class="batch-checkbox is-tablet-hidden">
          <mat-checkbox (change)="batchChecked($event, batchId, kpiLine)" color="primary" [matTooltip]="batchLineSelections.length >= 6 ? 'Selection limit 6 batch lines' : null">
          </mat-checkbox>
          <button
            class="vector-tinting-button"
            mat-icon-button
            *ngIf="showVectorButton"
            matTooltip="{{ 'dashboard.vectorTinting' | translate }}"
            [matTooltipPosition]="'above'"
            (click)="navigateToVectorTintingPage()"
          >
            <img alt="vectorTinting" src="assets/icon/vector_100x100.png"/>
          </button>
      </div>
  </div>
</div>
