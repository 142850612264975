import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MobileCheck} from '../../services/mobile-check/mobile-check.service';
import {IBatch} from '../../models/batch.model';
import {ProjectService} from '../../services/project/project.service';
import {IBatchForecastMeasurement} from '../../models/batch-forecast-measurement.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'colimo-forecast-measurement',
  templateUrl: './forecast-measurement.component.html',
  styleUrls: ['./forecast-measurement.component.scss']
  })
export class ForecastMeasurementComponent implements OnInit {
  @Input() public batch: IBatch;

  @Output() public selectedForecastMeasurementChanged: EventEmitter<IBatchForecastMeasurement> = new EventEmitter<IBatchForecastMeasurement>();
  
  public isMobile: boolean;
  public isForecastMeasurementLoading = false;
  public forecastMeasurementResult: string;
  public selectedForecastMeasurement: IBatchForecastMeasurement;

  constructor(
      private projectService: ProjectService,
      private mobileCheck: MobileCheck,
      private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.mobileCheck.getScreenSize().subscribe((deviceInfo) => {
      this.isMobile = deviceInfo.isMobile;
    });
  }

  public updateForecastMeasurementsByBatchId(): void {
    this.isForecastMeasurementLoading = true;
    this.projectService.updateForecastByBatchId({ batchId: this.batch.batchId }).subscribe({
      next: (numberOfNewMeasurements: number) => {
        if (numberOfNewMeasurements > 0) {
          this.reloadForecastMeasurements();
        }
        this.forecastMeasurementResult = this.getUpdateForecaseMeasurementsResultText(numberOfNewMeasurements);
        setTimeout(() => {
          this.forecastMeasurementResult = undefined;
        }, 10000);
      },
      error: () => {
        this.isForecastMeasurementLoading = false;
      },
      complete: () => {
        this.isForecastMeasurementLoading = false;
      },
    });
  }

  public selectedMeasurementChanged(selectedMeasurement: IBatchForecastMeasurement) {
    this.selectedForecastMeasurement = selectedMeasurement;
    this.selectedForecastMeasurementChanged.emit(this.selectedForecastMeasurement);
  }

  private getUpdateForecaseMeasurementsResultText(numberOfNew: number): string {
    if (numberOfNew >= 0) {
      return ' - ' + numberOfNew + ' ' + this.translate.instant('dashboard.updatedForecastMeasurements');
    } else {
      return ' - ' + this.translate.instant('dashboard.errorUpdatingForecastMeasurements');
    }
  }

  private reloadForecastMeasurements(): void {
    this.isForecastMeasurementLoading = true;
    this.projectService.getForecastMeasurementsByBatchId({ batchId: this.batch.batchId }).subscribe({
      next: (forecastMeasurements: IBatchForecastMeasurement[]) => {
        if (forecastMeasurements) {
          // reset current selection after update
          this.selectedMeasurementChanged(null)
          this.batch.forecastMeasurements = forecastMeasurements;
        }
      },
      error: () => {
        this.isForecastMeasurementLoading = false;
      },
      complete: () => {
        this.isForecastMeasurementLoading = false;
      },
    });
  }
}
