import {Injectable} from '@angular/core';
import {OffsetDto} from "../../models/vector-api/offset-dto.model";
import {IBatchForecastMeasurement} from "../../models/batch-forecast-measurement.model";

@Injectable({
  providedIn: 'root'
})
export class OffsetHistoryService {

  // prefix for offset history in storage
  public static OFFSET_STORAGE_PREFIX: string = 'OFFSET-B_';

  // storage for storing offset history -> session or local storage can be used session storage will be preferred
  private storage: Storage;

  constructor() {
    // switch between session or localstorage
    this.storage = localStorage;
    //this.storage = sessionStorage;
  }

  public addToHistory(refBatch: any, refMeasurement: IBatchForecastMeasurement, offset: OffsetDto): void {
    const identifier = this.getIdentifier(refBatch, refMeasurement);
    this.saveToStorage(identifier, offset);
  }

  public getFromHistory(refBatch: any, refMeasurement: IBatchForecastMeasurement): OffsetDto {
    const identifier = this.getIdentifier(refBatch, refMeasurement);
    return this.getFromStorage(identifier);
  }

  private getIdentifier(refBatch: any, refMeasurement: IBatchForecastMeasurement): string {
    if (refMeasurement) {
      return OffsetHistoryService.OFFSET_STORAGE_PREFIX + refBatch.id + '-MQC_' + refMeasurement.id;
    } else {
      return OffsetHistoryService.OFFSET_STORAGE_PREFIX + refBatch.id;
    }
  }

  private saveToStorage(identifier: string, offset: OffsetDto): void {
    this.storage.setItem(identifier, JSON.stringify(offset));
  }

  private getFromStorage(identifier: string): OffsetDto {
    return JSON.parse(this.storage.getItem(identifier));
  }

  /*
  // currently deleting is done via vector history service
  private deleteFromStorage(identifier: string): void {
    this.storage.removeItem(identifier);
  }*/
}
